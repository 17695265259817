const prod = {
    url: {
        API_URL: "https://pipeline-service.truenaturalgas.com",
        SOCKET_URL: "https://pipeline-service.truenatrualgas.com"
    },
    userfront:{
        tenantId: "5nxp448n",
        signup: "rblarm",
        login: "akdlmd",
        logout: "bblana",
        passwordReset: "lmolab"
    }
}
const dev = {
    url: {
        API_URL: 'http://localhost:8080',
        SOCKET_URL: "http://localhost:8080"
    },
    userfront:{
        tenantId: "5nxp448n",
        signup: "rblarm",
        login: "akdlmd",
        logout: "bblana",
        passwordReset: "lmolab"
    }
}

export const config = process.env.NODE_ENV === 'development' ? dev: prod;