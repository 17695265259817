/* global $ */

import React, { useEffect, useState } from "react";
import ListCommandBar from "./ListCommandBar"
import { FindStatus, FindStatusId, TimestampFormat, InquiryTypes, InquiryTypeTags, ChannelTypes, GetUserName } from "../Utils";
import { initialAssignmentsParser, initialChannelParser } from "../Utils";
import Gravatar from 'react-gravatar';
const GetInquiries = (props) => {
    const { inquiryList, inquiry, userList, currentUser, buddyList, increaseList } = props

    const [sortConfig, setSortConfig] = useState({
        key: localStorage.getItem("InitialSortPref") ? localStorage.getItem("InitialSortPref") : "CreatedOn", 
        direction: localStorage.getItem("InitialSortDir") ? localStorage.getItem("InitialSortDir") : "ascending"
    });

    

    const [filterConfig, setFilterConfig] = useState({
        status: [(localStorage.getItem("InitialStatus") ? localStorage.getItem("InitialStatus") : "all")],
        channel: initialChannelParser(),
        categories: (localStorage.getItem("InitialCategories") ? JSON.parse(localStorage.getItem("InitialCategories")) : []),
        assignment: [],
        provider: [(localStorage.getItem("InitialProvider") ? localStorage.getItem("InitialProvider") : "all")],
        search: ""
    });
    ;
    
    const statusColors = {
        0: "primary",
        1: "danger",
        2: "success"
    }

    const filterWrapper = (key, array) => {
        setFilterConfig({...filterConfig, [key]: array})
    }
    
    useEffect(() => {
        filterWrapper("assignment", initialAssignmentsParser(userList));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userList]);

    useEffect(() => {
        $('.pull-up.avatar').tooltip("enable");
    }, [buddyList]);

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

    }

    const getClassNamesFor = (name) =>{
        if (!sortConfig){ return "sortable"; }
        return sortConfig.key === name ? "sortable " + sortConfig.direction : "sortable";
    }

    const ShortName = (post) => {
        const first = post.FirstName? post.FirstName.trim() : ""
        const last = post.LastName? post.LastName.trim() : ""
        if (first.length + last.length > 30){
            const shortFirst =  first.length > 15? first.slice(0,12) + "…" : first
            const shortLast =  last.length > 15? last.slice(0,12) + "…" : last
            if (!shortFirst){ return shortLast }
            if (!shortLast){ return shortFirst }
            return shortLast + ", " + shortFirst
        }
        if (!first){ return last }
        if (!last){ return first }
        return last + ", " + first
    }

    const filteredInquiryList = React.useMemo(() => {
        let filteredList = [...inquiryList];
        filteredList = filteredList.filter( item => item.IsRemoved === false )
        if(filterConfig.search.length > 0){
            filteredList = filteredList.filter( item => {
                const search = filterConfig.search.toLowerCase().trim()
                const first = item.FirstName? item.FirstName.toLowerCase().trim() : "";
                const last =  item.LastName? item.LastName.toLowerCase().trim() : "";
                const email =  item.EmailAddress? item.EmailAddress.toLowerCase().trim() : "";
                const buisness = item.BusinessName? item.BusinessName.toLowerCase().trim() : "";
                const addresses = ((item.ContactAddressLine1? item.ContactAddressLine1:"") + (item.BillingAddressLine1? item.BillingAddressLine1:"") + (item.ServiceAddressLine1? item.ServiceAddressLine1:"")).toLowerCase();
                const zips = ((item.ContactZip? item.ContactZip:"") + (item.BillingZip? item.BillingZip:"") + (item.ServiceZip? item.ServiceZip:"")).toLowerCase(); //Yeah, they're all numbers, but whatever.
                const accounts = ((item.AccountNumber? item.AccountNumber:"") + (item.AglcAccountNumber? item.AglcAccountNumber:"")).toLowerCase();
                return (first.includes(search)||
                last.includes(search)||
                (first + " " + last).includes(search)||
                (last + ", " + first).includes(search)||
                (last + " " + first).includes(search)||
                email.includes(search)||
                buisness.includes(search))||
                addresses.includes(search)||
                zips.includes(search)||
                accounts.includes(search)
            });
        }
        if(filterConfig.status.includes("pending")){
            filteredList = filteredList.filter( item => item.IsProcessed === false && item.IsHeld === false)
        }
        if(filterConfig.status.includes("processed")){
            filteredList = filteredList.filter( item => item.IsProcessed === true)
        }
        if(filterConfig.status.includes("onHold")){
            filteredList = filteredList.filter( item => item.IsHeld === true)
        }
        if(filterConfig.channel.length > 0){
            filteredList = filteredList.filter( item => filterConfig.channel.includes(item.ChannelId))
        }
        if(filterConfig.categories.length > 0){
            filteredList = filteredList.filter( item => filterConfig.categories.includes(item.InquiryTypeId))
        }
        if(filterConfig.assignment.length > 0){
            filteredList = filteredList.filter( item => filterConfig.assignment.includes(item.AssignedTo))
        }
        if(filterConfig.provider.includes("sawnee")){ //This is true for sawnee, false for CFEMC
            filteredList = filteredList.filter( item => item.IsSawnee)
        }
        if(filterConfig.provider.includes("cfemc")){ //This is true for sawnee, false for CFEMC
            filteredList = filteredList.filter( item => item.IsSawnee === false)
        }
        return filteredList;
    }, [inquiryList, filterConfig]);

    const sortedInquiryList = React.useMemo(() => {
        let sortedInquiryList = [...filteredInquiryList];
        if (sortConfig !== null) {
            sortedInquiryList.sort((a, b) => {
                if(sortConfig.key === "Status"){
                    const aPower = (a.IsProcessed?4:0) + (a.IsHeld?2:0) + (a.IsSawnee?1:0);
                    const bPower = (b.IsProcessed?4:0) + (b.IsHeld?2:0) + (b.IsSawnee?1:0);
                    if (aPower < bPower) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (aPower > bPower) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                }
                if(["LastName", "BusinessName"].includes(sortConfig.key)){
                    const aString = a[sortConfig.key]? a[sortConfig.key].trim().toLowerCase() : "";
                    const bString = b[sortConfig.key]? b[sortConfig.key].trim().toLowerCase() : "";
                    if (sortConfig.direction === 'ascending'){
                        return aString.localeCompare(bString, undefined, {sensitivity: 'accent'});
                    }
                    return (aString.localeCompare(bString, undefined, {sensitivity: 'accent'})) * -1; //Flip the sort order
                }
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortedInquiryList;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredInquiryList, sortConfig]);


    return (
        <>
        <div className="list-header-wrapper container-fluid mx-0 px-4">
            <ListCommandBar userList={userList} currentUser={currentUser} filterWrapper={filterWrapper} />
        </div>
        <div className="container-fluid list-content">

            <div className={"table-responsive inquiry-list " + ((!inquiry || inquiry.length === 0) ? "no-selected-inquiry" : "selected-inquiry")}>

                <table className="table table-striped table-sm table-hover datatables-basic">
                    <thead>
                        <tr>
                            <th className="d-none microstatus"><span title="Status">S</span></th>
                            <th className={getClassNamesFor("LastName")} onClick={() => requestSort("LastName")}>Customer</th>
                            <th className={getClassNamesFor("BusinessName")} onClick={() => requestSort("BusinessName")}>Business</th>
                            <th className={getClassNamesFor("InquiryTypeId")}  onClick={() => requestSort("InquiryTypeId")}>Category</th>
                            <th className={getClassNamesFor("ChannelId")}  onClick={() => requestSort("ChannelId")}>Channel</th>
                            <th className={getClassNamesFor("CreatedOn")}  onClick={() => requestSort("CreatedOn")}>Created On</th>
                            <th className={getClassNamesFor("LastUpdatedOn")}  onClick={() => requestSort("LastUpdatedOn")}>Last Updated</th>
                            <th className={getClassNamesFor("AssignedTo")}  onClick={() => requestSort("AssignedTo")}>Assigned To</th>
                            <th className={getClassNamesFor("Status")}  onClick={() => requestSort("Status")}>Status</th>
                            <th className={getClassNamesFor("IsUtilitySubmitted")}  onClick={() => requestSort("IsUtilitySubmitted")}>Utility.org</th>
                            <th className="debug-only">Notes</th>
                            <th className="debug-only">Provider</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedInquiryList.map(post => (
                            <tr key={post.InquiryId} className={post === inquiry ? "selected" : ""} onClick={() => props.onClick(post)}>
                                <td className="d-none microstatus">
                                    <span className={"detail-view-microcategory badge bg-label-" + InquiryTypeTags[post.InquiryTypeId]} >{InquiryTypes[post.InquiryTypeId] ? InquiryTypes[post.InquiryTypeId].substring(0, 1) : "Unknown/" + post.InquiryTypeId}</span>
                                    <span className={"detail-view-microcategory badge-center icon badge bg-label-" + statusColors[FindStatusId(post)]} ><i className={"bx " + FindStatus(post, true)}></i></span>
                                </td>
                                <td>
                                    {ShortName(post)}
                                    <div className="avatar-group d-flex float-start">
                                        {buddyList.map(buddy => {
                                            if (buddy && buddy.inquiry === post.InquiryId){
                                                return (
                                                    <div key={buddy.id}  data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" title={buddy.name} className="pull-up avatar avatar-xs  ">
                                                        <Gravatar size={32} default="identicon" className="buddy-icon rounded-circle" email={buddy.user} />
                                                    </div>
                                                )
                                            }
                                            return(<></>)
                                        })}
                                    </div>
                                </td>
                                <td>{post.BusinessName}</td>
                                <td><span className={"badge bg-label-" + InquiryTypeTags[post.InquiryTypeId]} >{InquiryTypes[post.InquiryTypeId] ? InquiryTypes[post.InquiryTypeId] : "Unknown/" + post.InquiryTypeId}</span></td>
                                <td>{ChannelTypes[post.ChannelId]}</td>
                                <td>{TimestampFormat(post.CreatedOn)}</td>
                                <td>{TimestampFormat(post.LastUpdatedOn)}</td>
                                <td>{GetUserName(post.AssignedTo, userList)}</td>
                                <td>{FindStatus(post)}</td>
                                <td>{post.IsUtilitySubmitted ? 'Yes' : ''}</td>
                                <td className="debug-only"><span>{post.Notes > 0 ? post.Notes.toString() : ''}</span></td>
                                <td className="debug-only"><span>{post.IsSawnee ? "S" : 'C'}</span></td>
                            </tr>
                        ))}
                    </tbody>


                </table>
            </div>
        </div>
        <div className="footer-text text-light not-sawnee">
            {sortedInquiryList.length.toLocaleString()} out of {inquiryList.length.toLocaleString()} records shown. 
            
            <span className="bx bx-dots-horizontal-rounded" data-bs-toggle="dropdown"></span>
            <div className="dropdown-menu">
                <button onClick={() => increaseList(4000) } className="dropdown-item">Load 4,000 records (about a month's worth)</button>
                <button onClick={() => increaseList(12000) } className="dropdown-item">Load 12,000 records (about 3 month's worth)</button>
                <button onClick={() => increaseList(50000) } className="dropdown-item">Load 18 month's worth (≈80k, super slow)</button>

            </div>
        </div>
        </>
    )
}

export default GetInquiries;