import Moment from 'moment';
import {config} from '../services/Constants'
import forge from 'node-forge';

export const FindStatus = (inquiry, icon = false) =>{
    var source;
    if (icon){ 
        source = StatusIcons;
    } else {
        source = Statuses;
    }
    var returnValue = ""
    if (inquiry.IsHeld){ returnValue = source.Hold}
    else if (inquiry.IsProcessed){ returnValue = source.Processed}
    else { returnValue = source.Pending }
    if (icon) {return returnValue}
    if (inquiry.IsProcessed && inquiry.SawneeProcessed) { returnValue += " (by Sawnee)"; return returnValue;}
    if (inquiry.IsSawnee) { returnValue += " - Sawnee"}
    if (inquiry.SawneeNeedsCreditCheck) { returnValue += " - Needs Credit Check"}
    return returnValue
}

export const FindStatusId = (inquiry) =>{
    if (inquiry.IsHeld){
        return 1
    } else if (inquiry.IsProcessed) {
        return 2
    } else {
        return 0
    }
}
export const Statuses = {
    Hold: "On Hold",
    Processed: "Processed",
    Pending: "Pending",
}
export const StatusIcons = {
    Hold: "bxs-hand",
    Processed: "bx-check",
    Pending: "bx-flag",

}

export const RatePlans = {
    0: "Unknown",
    99: "Fixed 24 Month",
    100: "Fixed 18 Month",
    101: "Fixed 12 Month",
    102: "Fixed 6 Month",
    103: "Fixed 12 Month Promotional",
    121: "Fixed 12 Month DDDC",
    122: "Fixed 18 Month DDDC",
    201: "Variable",
    221: "Variable DDDC",
    301: "No Obligation Large Business",
}

export const InquiryTypes = {
    1: "General Inquiry",
    2: "New Service",
    3: "Switch To",
    4: "Renew Fixed",
    5: "Lock-in Fixed",
    6: "Disconnect",
    7: "Update Billing"
}
export const InquiryTypeTags = {
    1: "secondary",
    2: "primary",
    3: "success",
    4: "info",
    5: "warning",
    6: "danger",
    7: "dark"
}
export const ChannelTypes = {
    1: "Residential",
    2: "Commercial"
}
export const Assignments = { //Replace with users
    0: "Unassigned",
    1: "CSR 1",
    2: "CSR 2",
    3: "CSR 3"
}
export const MarketSources = {
    0: "Unknown",
    1: "Web Search",
    2: "Radio",
    3: "TV",
    4: "TrustDale",
    5: "Print Ad",
    6: "Other"

}
export const InitialEmailTemplates = [
    
        {
            title: "Switch", 
            body: 
`Thank you for switching to True Natural Gas! Your switch request has been received and processed.

This switch will be effective as of _DATE_ with the _RATE_.

If you need any further assistance please contact customer service at 770-502-0226.

This is a 12 month fixed rate contract, any termination or disconnection of services before the end of the 12 months will be charged a $150.00 fee.`, 
            notes: false,
            created: new Date().getTime()
        },
        {
            title: "Denied Credit", body: "True Natural Gas was unable to establish natural gas services at the following address based on the credit report: _SERVICE_ADDRESS_", notes: false, created: new Date().getTime()
        },
        {
            title: "Disconnect", body: "Your request to disconnect service at the following address has been submitted for _DATE_.\r\n _SERVICE_ADDRESS_", notes: false, created: new Date().getTime()
        },
        {
            title: "New Gas Service", 
            body:
`Your request for natural gas services has been processed for _DATE_.

AGL will need someone 18yrs or older to be at the location on _DATE_ between the hours _TIME_ to complete your order.
On your first bill there is a $50.00 connect fee and a $150.00 deposit. You have the option of waiving the deposit by a credit check or a 12-month letter of credit with another utility company. The credit score needs to 620 or higher and the 12 month letter of credit needs to have 2 late payments or less.

If you have any further questions please contact us at 770-502-0226.`, 
            notes: false,
            created: new Date().getTime()
        }
    

]

export const initialAssignmentsParser = (userList) => {
    if (!localStorage.getItem("InitialAssignments")){ return [];}
    const everyoneElse = [...userList.map((user) => {
        if (isMember(user) && !user.isCurrentUser){
            return(user.userId + 1000);
        }
        return null;
    }).filter(user => user), 1, 2, 3];
    const currentUser = userList.filter(user => {return (user.isCurrentUser)});
    const currentUserId = currentUser.length > 0? currentUser[0].userId + 1000 : null;
    var assignedUserIds = [];
    if (localStorage.getItem("InitialAssignments").includes("you")){ 
        assignedUserIds = [...assignedUserIds, ...(currentUserId? [currentUserId] : [])];
    }
    if (localStorage.getItem("InitialAssignments").includes("unassigned")){ 
        assignedUserIds = [...assignedUserIds, 0];
    }
    if (localStorage.getItem("InitialAssignments").includes("others")){ 
        assignedUserIds = [...assignedUserIds, ...everyoneElse];
    }
    return assignedUserIds;
}

export const initialChannelParser = () => {
    if (!localStorage.getItem("InitialChannel") || localStorage.getItem("InitialChannel") === "all"){ return 0;}
    if ( localStorage.getItem("InitialChannel") === "residential" ){ return 1;}
    return 2;
}


export const DecryptSSN = (encrypted) => {
    if(!encrypted){return;}
    try{
        const privateKey = forge.pki.privateKeyFromPem(localStorage.getItem("RSAKey"));
        const decoded = encrypted.match(/\w{2}/g).map(function(a){return String.fromCharCode(parseInt(a, 16));} ).join("");
        return privateKey.decrypt(decoded);
    } catch {
        return false;
    } 


}
///<summary>
///Converts MS-SQL formatted date stamps to a more human readable format
///</summary>
export const TimestampFormat = (timestamp, mode="time") => {
    if (timestamp === null){return "";}
    const unixTime = new Date(timestamp.replace('Z',''));
    Moment.locale('en');
    
    if (mode === "day"){
        return Moment(unixTime).format('MMMM Do YYYY');
    }
    return Moment(unixTime).format('MM/D/YY h:mm A');
}

export const AddressFormat = (addr1, addr2, city, state, zip) => {
    let address = "";
    if (addr1) { address = address + addr1 }
    if (addr2) { address = address + ", " +  addr2 }
    if (addr1 || addr2) { address = address + "\n" }
    if (city) { address = address + city + "," }
    if (state) { address = address + " " + state }
    if (zip) { address = address + " " + zip }
    return address
}

export const GetUserName = (userId, userList = []) => {
    if (userId < 1000){
        return Assignments[userId]? Assignments[userId] : "Unknown User/" + userId;
    }
    const user = userList.find((user) => {
        return user.userId + 1000 === userId
    });

    return user? user.name : "Unknown User/" + userId;
}

export const isAdminPanel = (user) => {
    if (Object.keys(user.data).length !== 0){
        if(user.data.hasOwnProperty('admin-panel')){
            if(user.data['admin-panel'] === true){
                return true;
            }
        }
    }
    return false;
}

export const isMember = (user) => {
    if (Object.keys(user.authorization).length !== 0){
        if(user.authorization[config.userfront.tenantId].roles.includes("member")){
            return true;
        };
        if(user.authorization[config.userfront.tenantId].roles.includes("admin")){
           // return true;
        };
    }
    return false;
}

export const isSawnee = (user) => {
    if (!user){ return false;}
    if (Object.keys(user.authorization).length !== 0){
        if(user.authorization[config.userfront.tenantId].roles.includes("contributor")){
            return true;
        };
    }
    return false;
}
