import React, { useState, useEffect } from 'react';
import Gravatar from 'react-gravatar';
import { isSawnee } from '../Utils';

export default function Preferences(props) {

  const [RSAKey, setRSAKey] = useState(localStorage.getItem("RSAKey") ? "Key already installed" : "");
  const [initialSortPref, setInitialSortPref] = useState(localStorage.getItem("InitialSortPref") ? localStorage.getItem("InitialSortPref") : "");
  const [initialSortDir, setInitialSortDir] = useState(localStorage.getItem("InitialSortDir") ? localStorage.getItem("InitialSortDir") : "ascending");

  const [initialStatus, setInitialStatus] = useState(localStorage.getItem("InitialStatus") ? localStorage.getItem("InitialStatus") : "all");
  const [initialChannel, setInitialChannel] = useState(localStorage.getItem("InitialChannel") ? localStorage.getItem("InitialChannel") : "all");
  const [initialProvider, setInitialProvider] = useState(localStorage.getItem("InitialProvider") ? localStorage.getItem("InitialProvider") : "all");

  const [initialCategories, setInitialCategories] = useState(localStorage.getItem("InitialCategories") ? JSON.parse(localStorage.getItem("InitialCategories")) : [
    1, 2, 3, 4, 5, 6, 7
  ]);
  const [initialAssignments, setInitialAssignments] = useState(localStorage.getItem("InitialAssignments") ? JSON.parse(localStorage.getItem("InitialAssignments")) : [
    "you",
    "unassigned",
    "others"
  ]);

  const [updateStorage, setUpdateStorage] = useState(0);
  const [templateList, setTemplateList] = useState({ email: [], notes: [] });
  const [templateNotesTitle, setTemplateNotesTitle] = useState("");
  const [templateNotesBody, setTemplateNotesBody] = useState("");
  const [templateEmailTitle, setTemplateEmailTitle] = useState("");
  const [templateEmailBody, setTemplateEmailBody] = useState("");
  
  const [selectedNotesTemplate, setSelectedNotesTemplate] = useState([{ title: "" }]);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState([{ title: "" }]);
  
  const { user } = props;
  const userEmail = user? user.email : "";

  const handleNotesTemplateChange = (e) => {
    const chosenTemplate = templateList.notes.find(template => e.target.value === template.title);
    if (chosenTemplate) {
      setSelectedNotesTemplate([chosenTemplate]);
      setTemplateNotesTitle(chosenTemplate.title);
      setTemplateNotesBody(chosenTemplate.body);
    } else {
      setSelectedNotesTemplate([{ title: "" }]);
    }
  }
  const handleEmailTemplateChange = (e) => {
    const chosenTemplate = templateList.email.find(template => e.target.value === template.title);
    if (chosenTemplate) {
      setSelectedEmailTemplate([chosenTemplate]);
      setTemplateEmailTitle(chosenTemplate.title);
      setTemplateEmailBody(chosenTemplate.body);
    } else {
      setSelectedEmailTemplate([{ title: "" }]);
    }
  }


  const handleSaveClick = () => {
    let db;
    const openRequest = indexedDB.open('pipeline', 2);
    openRequest.onsuccess = function (e) {
      db = e.target.result;
      const tx = db.transaction('templates', 'readwrite');
      const store = tx.objectStore('templates');
      store.put({ title: templateNotesTitle, body: templateNotesBody, notes: true }, "N" + templateNotesTitle)
      setSelectedNotesTemplate([{ title: templateNotesTitle, body: templateNotesBody, notes: true }]);
    };
    console.log([{ title: templateNotesTitle, body: templateNotesBody, notes: true }]);
    setUpdateStorage(current => current + 1);
  }

  const handleDeleteClick = () => {
    let db;

    const openRequest = indexedDB.open('pipeline', 2);

    openRequest.onsuccess = function (e) {
      db = e.target.result;
      const tx = db.transaction('templates', 'readwrite');
      const store = tx.objectStore('templates');
      const request = store.delete("N" + templateNotesTitle);
      request.onerror = function (e) {
        console.log("Error", e.target.error.name);
      }
      //addItem();
    };
    setTemplateNotesBody("");
    setTemplateNotesTitle("");
    setUpdateStorage(current => current + 1);
  }

  const handleEmailSaveClick = () => {
    let db;
    const openRequest = indexedDB.open('pipeline', 2);
    openRequest.onsuccess = function (e) {
      db = e.target.result;
      const tx = db.transaction('templates', 'readwrite');
      const store = tx.objectStore('templates');
      store.put({ title: templateEmailTitle, body: templateEmailBody, notes: false }, "E" + templateEmailTitle)
    };
    setUpdateStorage(current => current + 1);
  }

  const handleEmailDeleteClick = () => {
    let db;

    const openRequest = indexedDB.open('pipeline', 2);

    openRequest.onsuccess = function (e) {
      db = e.target.result;
      const tx = db.transaction('templates', 'readwrite');
      const store = tx.objectStore('templates');
      const request = store.delete("E" + templateEmailTitle);
      request.onerror = function (e) {
        console.log("Error", e.target.error.name);
      }
      //addItem();
    };
    setTemplateEmailBody("");
    setTemplateEmailTitle("");
    setUpdateStorage(current => current + 1);
  }


  const handleTemplateEmailBodyChange = (e) => {
    setTemplateEmailBody(e.target.value);
  }
  const handleTemplateNotesBodyChange = (e) => {
    setTemplateNotesBody(e.target.value);
  }

  const handleTemplateEmailTitleChange = (e) => {
    setTemplateEmailTitle(e.target.value);
  }
  const handleTemplateNotesTitleChange = (e) => {
    setTemplateNotesTitle(e.target.value);
  }

  const handleInitialSortPrefChange = (e) => {
    localStorage.setItem("InitialSortPref", e.target.value);
    setInitialSortPref(e.target.value);
  }

  const handleInitialSortDirChange = (e) => {
    localStorage.setItem("InitialSortDir", e.target.value);
    setInitialSortDir(e.target.value);
  }

  const handleStatusChange = (e) => {
    localStorage.setItem("InitialStatus", e.target.value);
    setInitialStatus(e.target.value);
  }

  const handleChannelChange = (e) => {
    localStorage.setItem("InitialChannel", e.target.value);
    setInitialChannel(e.target.value);
  }

  const handleProviderChange = (e) => {
    localStorage.setItem("InitialProvider", e.target.value);
    setInitialProvider(e.target.value);
  }

  const handleCategoriesChange = (e) => {
    const eValue = parseInt(e.target.value);
    if (e.target.checked) { //toggle ON
      localStorage.setItem("InitialCategories", JSON.stringify([...initialCategories, eValue]))
      setInitialCategories([...initialCategories, eValue]);
    } else { //toggle OFF
      localStorage.setItem("InitialCategories", JSON.stringify(initialCategories.filter(item => item !== eValue)))
      setInitialCategories(initialCategories.filter(item => item !== eValue));
    }
  }

  const handleAssignmentsChange = (e) => {
    if (e.target.checked) { //toggle ON
      localStorage.setItem("InitialAssignments", JSON.stringify([...initialAssignments, e.target.value]))
      setInitialAssignments([...initialAssignments, e.target.value]);
    } else { //toggle OFF
      localStorage.setItem("InitialAssignments", JSON.stringify(initialAssignments.filter(item => item !== e.target.value)))
      setInitialAssignments(initialAssignments.filter(item => item !== e.target.value));
    }
  }

  const handleKeyChange = (event) => {
    setRSAKey(event.target.value);
  }

  const handleSetKey = () => {
    if (!RSAKey.includes("END RSA PRIVATE KEY")) {
      alert("Invalid key");
      return;
    }
    localStorage.setItem("RSAKey", RSAKey.trim());
  }

  const notesTemplate = React.useRef();
  const emailTemplate = React.useRef();
  const tab = window.location.hash;

  useEffect(() => {

    if (tab === "#notes-template"){
      notesTemplate.current.scrollIntoView();
    }
    if (tab === "#email-template"){
      emailTemplate.current.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    let db;
    const openRequest = indexedDB.open('pipeline', 2);

    openRequest.onsuccess = function (e) {
      db = e.target.result;
      const tx = db.transaction('templates', 'readonly');
      const store = tx.objectStore('templates');
      const request = store.getAll();
      request.onsuccess = function (e) {
        if (request.result === undefined) {
          console.log("Template Error");
        } else {
          console.log();
          setTemplateList({
            notes: [...request.result.filter(template => template.notes === true)],
            email: [...request.result.filter(template => template.notes === false)],
          });
        }
      }
      request.onerror = function (e) {
        console.error(e);
      }
    };
    openRequest.onerror = function (e) {
      console.log('onerror!');
      console.dir(e);
    };
  }, [updateStorage]);

  const renderEmailTab = () => {
    if(isSawnee(user)){
      return(<></>)
    } else {
      return (
        <li className="nav-item not-sawnee" id='email-template'>
          <button type="button" className={"nav-link " + (tab === "#email-template"? "active" : "") } role="tab" data-bs-toggle="tab" data-bs-target="#navs-top-profile" aria-controls="navs-top-profile" aria-selected="false">Email</button>
        </li>
      )   
    }
    

  }
  return (
    <>
      <div className='container pt-4'>
        <a href="/"><i className='bx bx-undo'></i>Back to Pipeline</a>
        <h2>Preferences</h2>
        <h3>Initial Sorting</h3>

        <div className="mb-3">
          <label htmlFor="selectSortPref" className="form-label">Initial Sort Preference</label>
          <select onChange={handleInitialSortPrefChange} className="form-select" id="selectSortPref" value={initialSortPref} aria-label="Select initial sorting preference">
            <option value="">No Preference</option>
            <option value="LastName">Customer Last Name</option>
            <option value="BusinessName">Business Name</option>
            <option value="InquiryTypeId">Category</option>
            <option value="ChannelId">Channel (Residential/Commercial)</option>
            <option value="CreatedOn">Creation Date</option>
            <option value="LastUpdatedOn">Last Updated Date</option>
            <option value="AssignedTo">Assignment</option>
            <option value="Status">Status</option>
            <option value="IsUtilitySubmitted">Utility.org Submitted</option>
          </select>

          <label htmlFor="selectSortOrder" className="form-label">Initial Sort Order</label>
          <select onChange={handleInitialSortDirChange} className="form-select" id="selectSortOrder" value={initialSortDir} aria-label="Select initial sorting order">
            <option value="ascending">Ascending</option>
            <option value="descending">Descending</option>
          </select>
        </div>

        <div className="col-md mb-3 not-sawnee">
          <small className="fw-semibold d-block">Initial Status</small>
          <div className="form-check form-check-inline mt-1">
            <input onChange={handleStatusChange} className="form-check-input" type="radio" name="radioStatusFilter" id="radioStatusFilterAll" value="all" checked={initialStatus === "all"} />
            <label className="form-check-label" htmlFor="radioStatusFilterAll">All</label>
          </div>
          <div className="form-check form-check-inline">
            <input onChange={handleStatusChange} className="form-check-input" type="radio" name="radioStatusFilter" id="radioStatusFilterPending" value="pending" checked={initialStatus === "pending"} />
            <label className="form-check-label" htmlFor="radioStatusFilterPending"><i className="bx bx-flag"></i> Pending</label>
          </div>
          <div className="form-check form-check-inline">
            <input onChange={handleStatusChange} className="form-check-input" type="radio" name="radioStatusFilter" id="radioStatusFilterOnHold" value="onHold" checked={initialStatus === "onHold"} />
            <label className="form-check-label" htmlFor="radioStatusFilterOnHold"><i className="bx bx-tada-hover bxs-hand"></i> Oh Hold </label>
          </div>
          <div className="form-check form-check-inline">
            <input onChange={handleStatusChange} className="form-check-input" type="radio" name="radioStatusFilter" id="radioStatusFilterProcessed" value="processed" checked={initialStatus === "processed"} />
            <label className="form-check-label" htmlFor="radioStatusFilterProcessed"><i className="bx bx-check"></i> Processed </label>
          </div>
        </div>

        <div className="col-md mb-3 not-sawnee">
          <small className="fw-semibold d-block">Initial Channel</small>
          <div className="form-check form-check-inline mt-1">
            <input onChange={handleChannelChange} className="form-check-input" type="radio" name="radioChannelFilter" id="radioChannelFilterAll" value="all" checked={initialChannel === "all"} />
            <label className="form-check-label" htmlFor="radioChannelFilterAll">All</label>
          </div>
          <div className="form-check form-check-inline">
            <input onChange={handleChannelChange} className="form-check-input" type="radio" name="radioChannelFilter" id="radioChannelFilterResidential" value="residential" checked={initialChannel === "residential"} />
            <label className="form-check-label" htmlFor="radioChannelFilterResidential">Residential</label>
          </div>
          <div className="form-check form-check-inline">
            <input onChange={handleChannelChange} className="form-check-input" type="radio" name="radioChannelFilter" id="radioChannelFilterCommercial" value="commercial" checked={initialChannel === "commercial"} />
            <label className="form-check-label" htmlFor="radioChannelFilterCommercial">Commercial</label>
          </div>
        </div>

        <div className="col-md mb-3 not-sawnee">
          <small className="fw-semibold d-block">Initial Categories</small>
          <div className="form-check form-check-inline mt-1">
            <input onChange={handleCategoriesChange} className="form-check-input" type="checkbox" id="chkCategoryGeneral" checked={initialCategories.includes(1)} value="1" />
            <label className="form-check-label" htmlFor="chkCategoryGeneral">General</label>
          </div>
          <div className="form-check form-check-inline">
            <input onChange={handleCategoriesChange} className="form-check-input" type="checkbox" id="chkCategoryNewService" checked={initialCategories.includes(2)} value="2" />
            <label className="form-check-label" htmlFor="chkCategoryNewService">New Service</label>
          </div>
          <div className="form-check form-check-inline">
            <input onChange={handleCategoriesChange} className="form-check-input" type="checkbox" id="chkCategorySwitch" checked={initialCategories.includes(3)} value="3" />
            <label className="form-check-label" htmlFor="chkCategorySwitch">Switch Service</label>
          </div>
          <div className="form-check form-check-inline">
            <input onChange={handleCategoriesChange} className="form-check-input" type="checkbox" id="chkCategoryRenew" checked={initialCategories.includes(4)} value="4" />
            <label className="form-check-label" htmlFor="chkCategoryRenew">Renew Fixed</label>
          </div>
          <div className="form-check form-check-inline">
            <input onChange={handleCategoriesChange} className="form-check-input" type="checkbox" id="chkCategoryLockIn" checked={initialCategories.includes(5)} value="5" />
            <label className="form-check-label" htmlFor="chkCategoryLockIn">Lock-in Fixed</label>
          </div>
          <div className="form-check form-check-inline">
            <input onChange={handleCategoriesChange} className="form-check-input" type="checkbox" id="chkCategoryDisconnect" checked={initialCategories.includes(6)} value="6" />
            <label className="form-check-label" htmlFor="chkCategoryDisconnect">Disconnect</label>
          </div>
          <div className="form-check form-check-inline">
            <input onChange={handleCategoriesChange} className="form-check-input" type="checkbox" id="chkCategoryUpdateBilling" checked={initialCategories.includes(7)} value="7" />
            <label className="form-check-label" htmlFor="chkCategoryUpdateBilling">Update Billing</label>
          </div>
        </div>

        <div className="col-md mb-3 not-sawnee">
          <small className="fw-semibold d-block">Initial Assignment</small>
          <div className="form-check form-check-inline mt-1">
            <input onChange={handleAssignmentsChange} className="form-check-input" type="checkbox" id="chkAssignmentYou" checked={initialAssignments.includes("you")} value="you" />
            <label className="form-check-label" htmlFor="chkAssignmentYou">You</label>
          </div>
          <div className="form-check form-check-inline">
            <input onChange={handleAssignmentsChange} className="form-check-input" type="checkbox" id="chkAssignmentUnassigned" checked={initialAssignments.includes("unassigned")} value="unassigned" />
            <label className="form-check-label" htmlFor="chkAssignmentUnassigned">Unassigned</label>
          </div>
          <div className="form-check form-check-inline">
            <input onChange={handleAssignmentsChange} className="form-check-input" type="checkbox" id="chkAssignmentOthers" checked={initialAssignments.includes("others")} value="others" />
            <label className="form-check-label" htmlFor="chkAssignmentOthers">Everyone Else</label>
          </div>
        </div>

        <div className="col-md mb-3 not-sawnee">
          <small className="fw-semibold d-block">Initial Provider</small>
          <div className="form-check form-check-inline mt-1">
            <input onChange={handleProviderChange} className="form-check-input" type="radio" name="radioProviderFilter" id="radioProviderFilterAll" value="all" checked={initialProvider === "all"} />
            <label className="form-check-label" htmlFor="radioProviderFilterAll">All</label>
          </div>
          <div className="form-check form-check-inline">
            <input onChange={handleProviderChange} className="form-check-input" type="radio" name="radioProviderFilter" id="radioProviderFilterCFEMC" value="cfemc" checked={initialProvider === "cfemc"} />
            <label className="form-check-label" htmlFor="radioProviderFilterCFEMC">CFEMC</label>
          </div>
          <div className="form-check form-check-inline">
            <input onChange={handleProviderChange} className="form-check-input" type="radio" name="radioProviderFilter" id="radioProviderFilterSawnee" value="sawnee" checked={initialProvider === "sawnee"} />
            <label className="form-check-label" htmlFor="radioProviderFilterSawnee">Sawnee</label>
          </div>
        </div>
        <h3>User Avater <Gravatar size={32} default="identicon" className="buddy-icon rounded-circle" email={userEmail} /></h3>
        <div className="mb-3">
          <p>Avatar created by Gravatar, a third party service that lets you set you own image for a number of sites based on your email address.</p>
          <p>Change or set your gravatar <a href="https://en.gravatar.com/" target="_blank" rel="noreferrer">here</a>.</p>
        </div>

        <h3>Security</h3>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">Security Key:</label>
          <textarea placeholder='--BEGIN RSA PRIVATE KEY--...' className="form-control" id="exampleFormControlTextarea1" rows="9" onChange={handleKeyChange} value={RSAKey}></textarea>
        </div>
        <div className="mb-3">
          <button onClick={handleSetKey} className="btn btn-primary mb-3">Save Security Key</button>
        </div>
        <div className="mb-3">

        </div>
        <div className="mb-3">
          <h3>Templates</h3>
          <div className="nav-align-top">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item" id='notes-template'>
                <button type="button" className={"nav-link " + (tab === "#email-template"? "" : "active") } role="tab" data-bs-toggle="tab" data-bs-target="#navs-top-home" aria-controls="navs-top-home" aria-selected="true">Notes</button>
              </li>
              {renderEmailTab()}
            </ul>
            <div className="tab-content">
              <div className={"tab-pane fade " + (tab === "#email-template"? "" : "show active")} id="navs-top-home" role="tabpanel">


                <div className='row' ref={notesTemplate} >
                  <div className='col'>
                    <label htmlFor="templateNotesSelector" className="form-label">Notes Templates</label>
                    <select multiple style={{ height: "269px" }} className="form-select" id="templateNotesSelector" aria-label="Multiple select example" value={selectedNotesTemplate.title} onChange={handleNotesTemplateChange}>
                      {templateList.notes.map(template => (
                        <option key={template.title} value={template.title}>{template.title}</option>
                      ))}
                    </select>
                  </div>
                  <div className='col'>
                    <label htmlFor="templateNotesTitle" className="form-label">Template Name</label>
                    <input className="form-control" id="templateNotesTitle" onChange={handleTemplateNotesTitleChange} value={templateNotesTitle} />

                    <label htmlFor="templateNotesBody" className="form-label">Body</label>
                    <textarea className="form-control" id="templateNotesBody" rows="8" onChange={handleTemplateNotesBodyChange} value={templateNotesBody}></textarea>
                    <button onClick={handleSaveClick} type="button" className="btn btn-primary float-end mt-2">Add/Save</button>
                    <button onClick={handleDeleteClick} type="button" className="btn btn-danger float-end me-2 mt-2">Delete</button>

                  </div>
                </div>

              </div>
              <div className={"tab-pane fade " + (tab === "#email-template"? "show active" : "")} id="navs-top-profile" role="tabpanel">

                <div className='row' ref={emailTemplate}>
                  <div className='col'>
                    <label htmlFor="templateEmailSelector" className="form-label">Email Templates</label>
                    <select multiple style={{ height: "269px" }} className="form-select" id="templateEmailSelector" aria-label="Multiple select example" value={selectedEmailTemplate.title} onChange={handleEmailTemplateChange}>
                      {templateList.email.map(template => (
                        <option key={template.title} value={template.title}>{template.title}</option>
                      ))}
                    </select>
                  </div>
                  <div className='col'>
                    <label htmlFor="templateEmailTitle" className="form-label">Template Name</label>
                    <input className="form-control" id="templateEmailTitle" onChange={handleTemplateEmailTitleChange} value={templateEmailTitle} />

                    <label htmlFor="templatesEmailBody" className="form-label">Body</label>
                    <textarea className="form-control" id="templatesEmailBody" rows="8" onChange={handleTemplateEmailBodyChange} value={templateEmailBody}></textarea>
                    <button onClick={handleEmailSaveClick} type="button" className="btn btn-primary float-end mt-2">Add/Save</button>
                    <button onClick={handleEmailDeleteClick} type="button" className="btn btn-danger float-end me-2 mt-2">Delete</button>

                  </div>
                </div>



              </div>
            </div>
          </div>
        </div>
      </div>






    </>

  );
}