import React, {useState, useEffect} from "react";
import {ChannelTypes, InquiryTypes, InquiryTypeTags} from "../Utils";
import NotesPanel from "./Notes";
import DetailsCommandBar from "./DetailsCommandBar";
import GetTimeline from "./GetTimeline";
import GetInquiryDetailsList from "./GetInquiryDetailsList";


const GetInquiryDetails = (props) => {
    const [updateCount, setUpdateCount] = useState(0);

    const {inquiry, inquiryList, inquiryChanger, inquiryListChanger, userList, templateList, socket} = props;
    const currentUser = userList.find(user=>user.isCurrentUser);

    useEffect(() => {

        if (inquiry && inquiry.LastName){
            document.title = inquiry.LastName + ", " + inquiry.FirstName + " - Pipeline";
            socket.emit("openedDetails", {id: socket.id, user: currentUser.email, name: currentUser.name, inquiry: inquiry.InquiryId});
        } else {
            document.title = "Pipeline";
            if (socket.id){
                socket.emit("openedDetails", {id: socket.id, user: currentUser.email, name: currentUser.name, inquiry: null});
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inquiry]);
    

    if (inquiry === null || !inquiry || inquiry.length === 0){
        return(<div className='inquiry-details empty'>Null</div>);
    }

    const handleNoteRefresh = () =>{
        setUpdateCount(updateCount => updateCount + 1)
    }

    
    return(
    <>
        <div className={"inquiry-type-" + inquiry.InquiryTypeId + " inquiry-details"}>
            <div className="details-close-wrapper">
                <div className="details-close-button" onClick={()=> props.onClick([])}>&times;</div>
            </div>
            <div className="details-header">

                <DetailsCommandBar socket={socket} userList={userList} inquiryChanger={inquiryChanger} inquiryList={inquiryList} inquiryListChanger={inquiryListChanger} inquiry={inquiry} />
                    
                <div className="details-overview-bar">
                    <h2>{inquiry.FirstName} {inquiry.MiddleName} {inquiry.LastName} {inquiry.IsRemoved ? "(Removed)": ""}</h2>
                    <div>
                    <span className={"badge bg-label-" + InquiryTypeTags[inquiry.InquiryTypeId] } >{InquiryTypes[inquiry.InquiryTypeId]? InquiryTypes[inquiry.InquiryTypeId] : "Unknown/" + inquiry.InquiryTypeId}</span> - {ChannelTypes[inquiry.ChannelId]}
                    </div>
                </div>
            </div>
            <div className="details-table">
                <div className="left-panel">
                    <div className="card p-4 mx-2 details-list-card">
                        <GetInquiryDetailsList inquiry={inquiry} inquiryListChanger={inquiryListChanger} />
                    </div>
                </div>
                <div className="right-panel">
                    <div className="card p-4 pe-6 mx-2 mb-3 new-timeline-card">
                        <NotesPanel templateList={templateList} inquiry={inquiry} userList={userList} handleNoteRefresh={handleNoteRefresh} />
                    </div>
                    <div className="card p-4 pe-6 mx-2 timeline-card">
                        <GetTimeline updateCount={updateCount} inquiry={inquiry} />
                    </div>
                </div>
                
            </div>
        </div>





    </>
    
    );
}

export default GetInquiryDetails;
