import React from 'react';
import {config} from '../../services/Constants'
import './Login.scss';
import UserFront from "@userfront/react";
import { useEffect } from 'react';

UserFront.init(config.userfront.tenantId);

const LogoutButton = UserFront.build({
    toolId: "bblana"
});
const SignupForm = UserFront.build({
    toolId: "rblarm",
  });
  const LoginForm = UserFront.build({
    toolId: "akdlmd",
  });
  const PasswordResetForm = UserFront.build({
    toolId: "lmolab"
  });



  export default function Login() {
    useEffect(() => {
      document.body.classList.add('login');
      return function cleanup(){
        document.body.classList.remove('login');
      }
    }, []);
    return(
      <div className='container-fluid'>
        <div className='row'>
          <div className="login-wrapper">
              <h2>Welcome to <strong>Pipeline</strong></h2>
              <p>Please sign-in to your account and start the adventure</p>
              <LoginForm />
          </div>

        </div>
      </div>
    )
  }

  export const Logout = () => {
    useEffect(() => {
      document.body.classList.add('login');
      return function cleanup(){
        document.body.classList.remove('login');
      }
    }, []);

    return(
      <div className='container-fluid'>
        <div className='row'>
          <div className="login-wrapper">
              <LogoutButton />
          </div>

        </div>
      </div>
    )
}

export const Signup = () => {
    useEffect(() => {
      document.body.classList.add('login');
      return function cleanup(){
        document.body.classList.remove('login');
      }
    }, []);

    return(
      <div className='container-fluid'>
      <div className='row'>
        <div className="login-wrapper">
            <h2>Sign up for <strong>Pipeline</strong></h2>
            <p>Your account will <i>not</i> be active right away, but you do need to create your account first.</p>
            <SignupForm />
        </div>

      </div>
    </div>
    )
}


export const Reset = () => {
    return(
      <div className="container-fluid">
        <PasswordResetForm />
      </div>
    )
}
