import React, {useEffect, useState} from 'react';
import { getNotes } from '../../services/inquiry';
import { TimestampFormat } from "../Utils"

const GetEndstop = (props) => {
    const {anyNotes} = props;
    
    if (anyNotes > 0){
        return(
            <li className="timeline-end-indicator">
                <i className="bx bx-check-circle"></i>
            </li>
        )
    } 
    return(
        <li className="timeline-end-indicator">
            <i className="bx bx-circle"></i> <span>No Notes</span>
        </li>
    )
}
const GetErrorNotes = () => {
    return(
        <ul className="timeline timeline-dashed">
            <li className="timeline-end-indicator">
                <i className="bx bx-x-circle"></i> <span>Error retrieving Notes</span>
            </li>
        </ul>
    )
}

const GetTimeline = (props) => {
    const {inquiry, updateCount} = props;
    const [inquiryNotes, setInquiryNotes] = useState([]);

    useEffect(() => {
        const waitTimeout = setTimeout(()=>{
            setInquiryNotes([{Value: "Retrieving Notes", Wait: true}]);
        }, 100)
        getNotes(inquiry.InquiryId).then(d => {
            clearTimeout(waitTimeout);
            const enhancedNotes = d.map(obj => {
                if (detectEmail(obj)){
                    return {...obj, email: true}
                }
                return {...obj, email: false};
            })
            setInquiryNotes(enhancedNotes);
        }, function(error){
            setInquiryNotes([{Value: "Error retrieving Notes", Error: true}]);
        });

    }, [inquiry, updateCount])

    const detectEmail = (note) => {
        if (note.Value.includes("Sent email message")){
            return true;
        }
        return false;
    }
    const anyNotes = inquiryNotes.length;
    if (inquiryNotes[0] !== undefined && inquiryNotes[0].Wait !== undefined ){
        return(
            <div><i className='bx bx-loader-alt bx-spin'></i></div>
        );
    }    
    if (inquiryNotes[0] !== undefined && inquiryNotes[0].Error){
        return(
            <GetErrorNotes />
        );
    }
    return(
        <>
            <ul className="timeline timeline-dashed">
            {inquiryNotes.map(post=>(
                <li key={post.InquiryNoteId} className='timeline-item timeline-item-transparent'>
                    <span className={'timeline-indicator timeline-indicator-' + (post.email? "primary": "secondary") }>
                        <i className={'bx bx-' + (post.email? "envelope": "note")}></i>
                    </span>
                    <div className='timeline-header border-bottom mb-3'>
                        <h6>{post.email? "Sent Email Message" : "Note" + (post.Author? " - " + post.Author : "")}</h6>
                        <span>{TimestampFormat(post.LastUpdatedOn)}</span>
                    </div>
                    <p className={post.email? "note-email" : "note-note"}>{post.Value}</p>
                </li>
            ))}
                <GetEndstop anyNotes={anyNotes} />
            </ul>
        </>
    )
}

export default GetTimeline;