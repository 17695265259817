import React, {useState} from 'react';
import Gravatar from 'react-gravatar';
import {config} from '../../services/Constants'
import UserFront from "@userfront/react";

/*
window.templateCustomizer.setStyle("light")
window.templateCustomizer.setStyle("dark")
window.templateCustomizer.setTheme("theme-hometeam")
window.templateCustomizer.setTheme("theme-default")
window.templateCustomizer.setTheme("theme-semi-dark")
window.templateCustomizer.setTheme("theme-semi-dark")
window.templateCustomizer.setTheme("theme-bordered")
*/

export default function ProfileNav(props) {
    const { user, buildDate, lastViwedChangelog } = props;
    const [currentTheme, setCurrentTheme] = useState(window.templateCustomizer.settings.theme['name']);
    const [currentStyle, setCurrentStyle] = useState(window.templateCustomizer.settings.style);


    const handleThemeSwitcher = value => {
        window.templateCustomizer.setTheme(value.target.value);
        setCurrentTheme(value.target.value);
    }
    const handleStyleSwitcher = value => {
        let chosenStyle = value.target.checked? "dark" : "light";
        setCurrentStyle(chosenStyle);
        setTimeout(() => {
            window.templateCustomizer.setStyle(chosenStyle);
          }, 300)
    }
    const handleLogout = (e) => {
        e.preventDefault();
        UserFront.logout();
    }
    
    const lastViewedChangelogProfile = () => {
        if (buildDate !== lastViwedChangelog){
            return(
                <>
                <span style={{right: "-8px", top: "15px"}} className="position-absolute translate-middle p-2 bg-danger border border-light rounded-circle">
                  <span className="visually-hidden">New alerts</span>
                </span>
              </>
            )
        }
    }
    const lastViewedChangelogIcon = () => {
        if (buildDate !== lastViwedChangelog){
            return(
                <>
                <span style={{left: "-5px"}} className="position-absolute top-0 translate-middle p-1 bg-danger border border-light rounded-circle">
                  <span className="visually-hidden">New alerts</span>
                </span>
              </>
            )
        }
    }

    if (user){
        UserFront.init(config.userfront.tenantId);
        return (
            <>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id='navbarSupportedContent'>
                    <ul className='navbar-nav ms-auto mb-2 mb-lg-0 pull-end'>
                        <li className='nav-item navbar-dropdown dropdown-user dropdown'>
                            <button className="btn btn-link nav-link dropdown-toggle hide-arrow" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="true">
                                {lastViewedChangelogProfile()}
                                <Gravatar size={40} default="identicon" className="buddy-icon rounded-circle" email={user.email} />
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li className="dropdown-item"><a href="/preferences">Preferences</a></li>
                                <li className="dropdown-item"><a className='position-relative' href="/changelog">{lastViewedChangelogIcon()}What's New</a></li>
                                <li className='dropdown-item'><a href='/reset'>Reset Password</a></li>
                                <li className="dropdown-item">
                                    <div className="form-floating">
                                        <select onChange={handleThemeSwitcher} value={currentTheme} className='form-select' name="themeSelector" id="themeSelector">
                                            <option value="theme-default">Default</option>
                                            <option value="theme-semi-dark">Semi-Dark</option>
                                            <option value="theme-bordered">Bordered</option>
                                            <option value="theme-hometeam">Home Field Advantage</option>
                                        </select>
                                        <label htmlFor="themeSelector">Theme</label>
                                    </div>
                                </li>
                                <li className='dropdown-item'>
                                    <label className="switch switch-dark">
                                        <input type="checkbox" onChange={handleStyleSwitcher} checked={currentStyle === "dark"? true : false} className="switch-input" />
                                        <span className="switch-toggle-slider">
                                            <span className="switch-on">
                                                <i className="bx bx-moon"></i>
                                            </span>
                                            <span className="switch-off">
                                                <i className="bx bx-sun"></i>
                                            </span>
                                        </span>
                                        <span className="switch-label">Dark Mode</span>
                                    </label>
                                </li>
                                <li><button className='btn btn-link dropdown-item link-danger' onClick={handleLogout}>Logout</button></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </>
        )
    }
}