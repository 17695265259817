/* global $ */

import React, {useEffect, useState} from "react";
import {Assignments, isMember, isSawnee} from "../Utils";
import { initialAssignmentsParser, initialChannelParser } from "../Utils";

import jwt from 'jwt-decode';
import UserFront from "@userfront/react";


const ListCommandBar = (props) =>{
    const {userList, currentUser, filterWrapper} = props;
    //If the user is sawenee, set default filter to mostly all or pending. We don't want another user's localstorage to override. 
    const categories = (isSawnee(currentUser)? [] : localStorage.getItem("InitialCategories") ? JSON.parse(localStorage.getItem("InitialCategories")) : []);

    const [isGeneralChecked, setIsGeneralChecked] = useState(categories.includes(1));
    const [isNewServiceChecked, setIsNewServiceChecked] = useState(categories.includes(2));
    const [isSwitchChecked, setIsSwitchChecked] = useState(categories.includes(3));
    const [isRenewChecked, setIsRenewChecked] = useState(categories.includes(4));
    const [isLockInChecked, setIsLockInChecked] = useState(categories.includes(5));
    const [isDisconnectChecked, setIsDisconnectChecked] = useState(categories.includes(6));
    const [isUpdateBillingChecked, setIsUpdateBillingChecked] = useState(categories.includes(7));

    const [statusFilter, setStatusFilter] = useState(isSawnee(currentUser)? "pending" : (localStorage.getItem("InitialStatus") ? localStorage.getItem("InitialStatus") : "all"));
    const [channelFilter, setChannelFilter] = useState(isSawnee(currentUser) ? 0 : initialChannelParser());
    const [assignmentFilter, setAssignmentFilter] = useState(isSawnee(currentUser)? [] : initialAssignmentsParser(userList));
    const [providerFilter, setProviderFilter] = useState(isSawnee(currentUser) ? "all" : (localStorage.getItem("InitialProvider") ? localStorage.getItem("InitialProvider") : "all"));
    const [searchFilter, setSearchFilter] = useState(isSawnee(currentUser)? "true" : "");

    const user = jwt(UserFront.tokens.accessToken);



    useEffect(() => {
        if(isSawnee(currentUser)){
            setStatusFilter("pending");
            setChannelFilter(0);
            setAssignmentFilter([]);
            setProviderFilter("all");
            setIsGeneralChecked(true);
            setIsNewServiceChecked(true);
            setIsSwitchChecked(true);
            setIsRenewChecked(true);
            setIsLockInChecked(true);
            setIsDisconnectChecked(true);
            setIsUpdateBillingChecked(true);
        }
    }, [currentUser]);


    useEffect(() => {
        $('[data-bs-toggle="popover"]').popover("enable");
    }, []);

    useEffect(() => {
        setAssignmentFilter(initialAssignmentsParser(userList));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userList])

    useEffect(() => {
        filterWrapper("search", searchFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFilter]);

    useEffect(() => {
        filterWrapper("assignment", assignmentFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignmentFilter]);


    useEffect(() => {
        let categoryArray = [];
        if (isGeneralChecked){categoryArray.push(1)}
        if (isNewServiceChecked){categoryArray.push(2)}
        if (isSwitchChecked){categoryArray.push(3)}
        if (isRenewChecked){categoryArray.push(4)}
        if (isLockInChecked){categoryArray.push(5)}
        if (isDisconnectChecked){categoryArray.push(6)}
        if (isUpdateBillingChecked){categoryArray.push(7)}
        filterWrapper("categories", categoryArray);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isGeneralChecked, 
        isNewServiceChecked,
        isSwitchChecked,
        isRenewChecked,
        isLockInChecked,
        isDisconnectChecked,
        isUpdateBillingChecked
    ]);

    useEffect(() => {
        filterWrapper("status", statusFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusFilter]);

    useEffect(() => {
        filterWrapper("channel", channelFilter > 0? [channelFilter] : []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channelFilter]);

    useEffect(() => {
        filterWrapper("provider", [providerFilter]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerFilter]);

    const allUserIds = userList.reduce((result, user) => {
        if (isMember(user)){
            result.push(user.userId + 1000);
        }
        return result;
    }, []);
    
    const handleSearchFilter = (e) => {
        setSearchFilter(e.target.value);
    }
    const handleAssignmentFilter = (id, event) => {
        if(id === -1){
            setAssignmentFilter([...allUserIds, 0,1,2,3])
            return;
        }
        const idList = Array.isArray(id)? id: [id];
        setAssignmentFilter(idList);
    }
    
    const handleAssignmentClick = (event) =>{
        if(event.target.checked){
            setAssignmentFilter([...assignmentFilter, parseInt(event.target.dataset.value)])
        } else {
            setAssignmentFilter(assignmentFilter.filter((id) => {
                return parseInt(event.target.dataset.value) !== id;
            }))
        }
    }
    const handleCategoryFilter = event => {
        const filterId = event.target.id;
        switch (filterId) {
            case "chkFilterGeneral":
                setIsGeneralChecked(current => !current);
                break;
            case "chkFilterNewService":
                setIsNewServiceChecked(current => !current);
                break;
            case "chkFilterSwitch":
                setIsSwitchChecked(current => !current);
                break;
            case "chkFilterRenew":
                setIsRenewChecked(current => !current);
                break;
            case "chkFilterLockIn":
                setIsLockInChecked(current => !current);
                break;
            case "chkFilterDisconnect":
                setIsDisconnectChecked(current => !current);
                break;
            case "chkFilterUpdateBilling":
                setIsUpdateBillingChecked(current => !current);
                break;
            default:
                break;
        }
    }

    const handleStatusFilter = (statusState) => {
        setStatusFilter(statusState);
    }

    const handleChannelFilter = (channelState) => {
        setChannelFilter(channelState);
    }

    const handleProviderFilter = (providerState) => {
        setProviderFilter(providerState);
    }

    const handleSetCategoryCheckboxes = (...checked) => {
        setIsGeneralChecked(false);
        setIsNewServiceChecked(false);
        setIsSwitchChecked(false);
        setIsRenewChecked(false);
        setIsLockInChecked(false);
        setIsDisconnectChecked(false);
        setIsUpdateBillingChecked(false);
        checked.forEach(element => {
            if (element === "General"){setIsGeneralChecked(true);}
            if (element === "NewService"){setIsNewServiceChecked(true);}
            if (element === "Switch"){setIsSwitchChecked(true);}
            if (element === "Renew"){setIsRenewChecked(true);}
            if (element === "LockIn"){setIsLockInChecked(true);}
            if (element === "Disconnect"){setIsDisconnectChecked(true);}
            if (element === "UpdateBilling"){setIsUpdateBillingChecked(true);}
        });
    }

    return(
        <>
            <div className="list-header">

                <div className="list-command-bar">
                    <div>
                        <div className="form-text">Status</div>
                        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                            <input onChange={() => handleStatusFilter("all")} type="radio" checked={statusFilter === "all"} className="btn-check" name="btnFilterStatus" id="btnFilterStatusAll" autoComplete="off" />
                            <label className="btn btn-outline-primary btn-sm" htmlFor="btnFilterStatusAll"> All</label>

                            <input onChange={() => handleStatusFilter("pending")} type="radio" checked={statusFilter === "pending"} className="btn-check" name="btnFilterStatus" id="btnFilterStatusPending" autoComplete="off" />
                            <label className="btn btn-outline-primary btn-sm" htmlFor="btnFilterStatusPending"><span className="tf-icons bx bx-flag"></span> Pending</label>

                            <input onChange={() => handleStatusFilter("onHold")} type="radio" checked={statusFilter === "onHold"} className="btn-check" name="btnFilterStatus" id="btnFilterStatusHold" autoComplete="off" />
                            <label className="btn btn-outline-primary btn-sm not-sawnee" htmlFor="btnFilterStatusHold"><span className="tf-icons bx bx-tada-hover bxs-hand"></span> On Hold</label>

                            <input onChange={() => handleStatusFilter("processed")} type="radio" checked={statusFilter === "processed"} className="btn-check" name="btnFilterStatus" id="btnFilterStatusProcessed" autoComplete="off" />
                            <label className="btn btn-outline-primary btn-sm" htmlFor="btnFilterStatusProcessed"><span className="tf-icons bx  bx-check"></span> Processed</label>
                        </div>
                    </div>
                    <div className="not-sawnee">
                        <div className="form-text">Channel</div>
                        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                            <input onChange={() => handleChannelFilter(0)} type="radio" checked={channelFilter === 0} className="btn-check" name="btnFilterChannel" id="btnFilterChannelAll" autoComplete="off" />
                            <label className="btn btn-outline-primary btn-sm" htmlFor="btnFilterChannelAll"> All</label>

                            <input onChange={() => handleChannelFilter(1)} type="radio" checked={channelFilter === 1} className="btn-check" name="btnFilterChannel" id="btnFilterChannelResidential" autoComplete="off" />
                            <label className="btn btn-outline-primary btn-sm" htmlFor="btnFilterChannelResidential"><span className="tf-icons bx bx-home"></span> Residential</label>

                            <input onChange={() => handleChannelFilter(2)} type="radio" checked={channelFilter === 2} className="btn-check" name="btnFilterChannel" id="btnFilterChannelCommercial" autoComplete="off" />
                            <label className="btn btn-outline-primary btn-sm" htmlFor="btnFilterChannelCommercial"><span className="tf-icons bx bx-building"></span> Commercial</label>
                        </div>
                    </div>
                    <div className="not-sawnee">
                        <div className="form-text">Categories</div>
                        <button className="btn btn-sm btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                            {"Categories"}
                        </button>
                        <div className="dropdown-menu">
                            <button onClick={() => handleSetCategoryCheckboxes("General", "NewService", "Switch", "Renew", "LockIn", "Disconnect", "UpdateBilling")} className="dropdown-item" href="#todo">Select All</button>
                            <button onClick={() => handleSetCategoryCheckboxes()} className="dropdown-item" href="#todo">Select None</button>
                            <button onClick={() => handleSetCategoryCheckboxes("Renew", "LockIn")} className="dropdown-item" href="#todo">Select Renew + Lock-in Fixed</button>
                            <button onClick={() => handleSetCategoryCheckboxes("NewService", "Switch", "Disconnect", "UpdateBilling")} className="dropdown-item" href="#todo">New, Switch, Disconnect, Update</button>

                            <form className="px-4 py-3">
                                <div className="mb-3">
                                    <div className="form-check">
                                        <input onChange={handleCategoryFilter} checked={isGeneralChecked} type="checkbox" className="form-check-input" name="chkFilterGeneral" id="chkFilterGeneral" />
                                        <label className="form-check-label" htmlFor="chkFilterGeneral">General</label>
                                    </div>
                                    <div className="form-check">
                                        <input onChange={handleCategoryFilter} checked={isNewServiceChecked} type="checkbox" className="form-check-input" name="chkFilterNewService" id="chkFilterNewService" />
                                        <label className="form-check-label" htmlFor="chkFilterNewService">New Service</label>
                                    </div>
                                    <div className="form-check">
                                        <input onChange={handleCategoryFilter} checked={isSwitchChecked} type="checkbox" className="form-check-input" name="chkFilterSwitch" id="chkFilterSwitch" />
                                        <label className="form-check-label" htmlFor="chkFilterSwitch">Switch Service</label>
                                    </div>
                                    <div className="form-check">
                                        <input onChange={handleCategoryFilter} checked={isRenewChecked} type="checkbox" className="form-check-input" name="chkFilterRenew" id="chkFilterRenew" />
                                        <label className="form-check-label" htmlFor="chkFilterRenew">Renew Fixed</label>
                                    </div>
                                    <div className="form-check">
                                        <input onChange={handleCategoryFilter} checked={isLockInChecked} type="checkbox" className="form-check-input" name="chkFilterLockIn" id="chkFilterLockIn" />
                                        <label className="form-check-label" htmlFor="chkFilterLockIn">Lock-in Fixed</label>
                                    </div>
                                    <div className="form-check">
                                        <input onChange={handleCategoryFilter} checked={isDisconnectChecked} type="checkbox" className="form-check-input" name="chkFilterDisconnect" id="chkFilterDisconnect" />
                                        <label className="form-check-label" htmlFor="chkFilterDisconnect">Disconnect</label>
                                    </div>
                                    <div className="form-check">
                                        <input onChange={handleCategoryFilter} checked={isUpdateBillingChecked} type="checkbox" className="form-check-input" name="chkFilterUpdateBilling" id="chkFilterUpdateBilling" />
                                        <label className="form-check-label" htmlFor="chkFilterUpdateBilling">Update Billing</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="not-sawnee">
                        <div className="form-text">Assignment</div>
                        <button className="btn btn-sm btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                            {"Assignment"}
                        </button>
                        <div className="dropdown-menu assignments-menu">
                            <button onClick={() => handleAssignmentFilter(-1)} className="dropdown-item">Select All</button>
                            <button onClick={() => handleAssignmentFilter([])} className="dropdown-item">Select None</button>
                            <button onClick={() => handleAssignmentFilter([user.userId + 1000])} className="dropdown-item">Select You</button>
                            <button onClick={() => handleAssignmentFilter([0,user.userId + 1000])} className="dropdown-item">Select Unassigned + You</button>

                            <form className="px-4 py-3">
                                <div className="mb-3">
                                    <div className="form-check">
                                        <input type="checkbox" onChange={handleAssignmentClick} data-value={0} checked={assignmentFilter.includes(0)} id="assignment0" className="form-check-input" />
                                        <label htmlFor="assignment0" className="form-check-label">{Assignments[0]}</label>
                                    </div>
                                    {userList.filter(person => isMember(person)).map(post=>(
                                        <div key={post.userId} className="form-check">
                                            <input type="checkbox" onChange={handleAssignmentClick} checked={assignmentFilter.includes(post.userId+1000)} data-value={post.userId+1000}  id={"assingment-" + post.userId} className="form-check-input" />
                                            <label htmlFor={"assingment-" + post.userId} className="form-check-label">{post.name? post.name : post.email} {post.isCurrentUser? "(that's you!)" : ""  }</label>
                                        </div>
                                    ))}
                                    <div className="form-check">
                                        <input type="checkbox" onChange={handleAssignmentClick} checked={assignmentFilter.includes(1)} data-value={1} id="assignment1" className="form-check-input" />
                                        <label htmlFor="assignment1" className="form-check-label">{Assignments[1]}</label>
                                    </div>
                                    <div className="form-check">
                                        <input type="checkbox"  onChange={handleAssignmentClick} checked={assignmentFilter.includes(2)} data-value={2} id="assignment2" className="form-check-input" />
                                        <label htmlFor="assignment2" className="form-check-label">{Assignments[2]}</label>
                                    </div>
                                    <div className="form-check">
                                        <input type="checkbox" onChange={handleAssignmentClick} checked={assignmentFilter.includes(3)} data-value={3} id="assignment3" className="form-check-input" />
                                        <label htmlFor="assignment3" className="form-check-label">{Assignments[3]}</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="not-sawnee">
                        <div id="floatingInputHelp" className="form-text">Provider</div>

                        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                            <input onChange={() => handleProviderFilter("all")} type="radio"  checked={providerFilter.includes("all")} className="btn-check" name="btnFilterProvider" id="btnFilterProviderAll" autoComplete="off" />
                            <label className="btn btn-outline-primary btn-sm" htmlFor="btnFilterProviderAll"> All</label>

                            <input onChange={() => handleProviderFilter("cfemc")} type="radio"  checked={providerFilter.includes("cfemc")}  className="btn-check" name="btnFilterProvider" id="btnFilterProviderCFEMC" autoComplete="off" />
                            <label className="btn btn-outline-primary btn-sm" htmlFor="btnFilterProviderCFEMC">CFEMC</label>

                            <input onChange={() => handleProviderFilter("sawnee")} type="radio"  checked={providerFilter.includes("sawnee")}  className="btn-check" name="btnFilterProvider" id="btnFilterProviderSawnee" autoComplete="off" />
                            <label className="btn btn-outline-primary btn-sm" htmlFor="btnFilterProviderSawnee">Sawnee</label>
                        </div>
                    </div>
                    <div>
                        <div htmlFor="txtSearch" id="floatingInputHelp" className="form-text">Search <i data-bs-toggle="popover" title="Search" data-bs-content="Search by First or Last name (or both), email, business name, email address, address line, zip code, or account number (including AGLC)" className="bx bx-xs bx-help-circle"></i></div>
                            <div className="col-md-10">
                                <input onChange={handleSearchFilter} className="form-control form-control-sm" type="search" placeholder="Search ..." value={searchFilter} id="txtSearch" />
                            </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ListCommandBar;