import React from "react";
import {Assignments, isMember, isAdminPanel, GetUserName} from "../Utils";
import { setInquiryStatus, setInquiryAssignment, setNeedsCreditCheck, getInquiry } from "../../services/inquiry"

const RemoveButton = (props) => {
    const {inquiry, handleSetInquiryStatus, userList} = props;
    const currentUser = userList.filter(name => name.isCurrentUser)[0]
    if (!isAdminPanel(currentUser)){ return(<></>);}
    return(
        <>
            <div>
                <div id="floatingPrintHelp" className="form-text">&nbsp;</div>

                <div className="btn-group" role="group" aria-label="Basic button group">
                    <button onClick={() => handleSetInquiryStatus(inquiry, "removed")} className="btn-check" name="btnRemove" id="btnRemove">Remove</button>
                    <label className="btn btn-outline-warning btn-sm" htmlFor="btnRemove"><span className="tf-icons bx bx-folder-minus"></span> Remove</label>
                </div>
            </div>
        </>
    )
}

const DetailsCommandBar = (props) => {
    const {inquiry, inquiryList, inquiryChanger, inquiryListChanger, socket, userList} = props;
    
    const handleSetInquiryStatus = (inquiry, status) => {
        setInquiryStatus(inquiry.InquiryId, status).then(d=> {
            getInquiry(inquiry.InquiryId).then(newInquiry => {
                const newList = inquiryList.map(item => {
                    if (item.InquiryId === newInquiry.InquiryId){
                        return newInquiry;
                    }
                    return item;
                });
                inquiryListChanger(newList);
                inquiryChanger(newInquiry);
            });
            socket.emit("inquiryUpdate", {socket: socket.id, type: "Status"});
        });
    }

    const handleSetAssignment = (inquiry, assignmentId) => {
        setInquiryAssignment(inquiry.InquiryId, assignmentId).then(d=> {
            getInquiry(inquiry.InquiryId).then(newInquiry => {
                const newList = inquiryList.map(item => {
                    if (item.InquiryId === newInquiry.InquiryId){
                        return newInquiry;
                    }
                    return item;
                });
                inquiryListChanger(newList);
                inquiryChanger(newInquiry);
            });
            socket.emit("inquiryUpdate", {socket: socket.id, type: "Assign"});
        });
    }

    const handleSetNeedsCreditCheck = (inquiry) => {
        setNeedsCreditCheck(inquiry.InquiryId).then(d=> {
            getInquiry(inquiry.InquiryId).then(newInquiry => {
                const newList = inquiryList.map(item => {
                    if (item.InquiryId === newInquiry.InquiryId){
                        return newInquiry;
                    }
                    return item;
                });
                inquiryListChanger(newList);
                inquiryChanger(newInquiry);
            });
            socket.emit("inquiryUpdate", {socket: socket.id, type: "Credit Check"});
        });
    }
    
    return(
        <div className="details-command-bar">
            <div>
                <div className="form-text">Status</div>
                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input onChange={() => handleSetInquiryStatus(inquiry, "pending")} type="radio" className="btn-check" name="btnStatus" id="btnPending" autoComplete="off" checked={inquiry.IsProcessed === false && inquiry.IsHeld === false? true: false} />
                    <label className="btn btn-outline-primary btn-sm" htmlFor="btnPending"><span className="tf-icons bx bx-flag"></span> Pending</label>

                    <input onChange={() => handleSetInquiryStatus(inquiry, "onhold")} type="radio" className="btn-check" name="btnStatus" id="btnOnHold" autoComplete="off" checked={inquiry.IsHeld? true: false}/>
                    <label className="btn btn-outline-primary btn-sm" htmlFor="btnOnHold"><span className="tf-icons bx bx-tada-hover bxs-hand"></span> On Hold</label>

                    <input onChange={() => handleSetInquiryStatus(inquiry, "processed")} type="radio" className="btn-check" name="btnStatus" id="btnProcessed" autoComplete="off" checked={inquiry.IsProcessed? true: false} />
                    <label className="btn btn-outline-primary btn-sm" htmlFor="btnProcessed"><span className="tf-icons bx  bx-check"></span> Processed</label>
                </div>
            </div>
            <div className="only-sawnee">
                <div className="form-text">&nbsp;</div>
                <div className="btn-group" role="group" aria-label="Basic button group">
                    <input onChange={() => handleSetNeedsCreditCheck(inquiry)} type="radio" className="btn-check" name="btnCreditCheck" id="btnCreditCheck" autoComplete="off" checked={inquiry.SawneeNeedsCreditCheck? true: false} />
                    <label className="btn btn-outline-primary btn-sm" htmlFor="btnCreditCheck"><span className="tf-icons bx bxs-report"></span> Needs Credit Check</label>
                </div>
            </div>
            <div className="not-sawnee">
                <div className="form-text">Assignment</div>
                <button className="btn btn-sm btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {GetUserName(inquiry.AssignedTo, userList)}
                </button>
                <ul className="dropdown-menu assignments-menu assignments-menu-details" aria-labelledby="dropdownMenuButton">
                    <li><button onClick={() => handleSetAssignment(inquiry, 0)} className={"dropdown-item " + (inquiry.AssignedTo === 0 ? "disabled" : "")} >{Assignments[0]}</button></li>
                    {userList.filter(user => isMember(user)).map(user => (
                        <li key={user.userId}>
                            <button onClick={() => handleSetAssignment(inquiry, user.userId + 1000)} className={"dropdown-item " + (inquiry.AssignedTo === (user.userId+1000)? "disabled" : "")}>{user.name}</button>
                        </li>
                    ))}
                    <li><button onClick={() => handleSetAssignment(inquiry, 1)} className={"dropdown-item " + (inquiry.AssignedTo === 1 ? "disabled" : "")} >{Assignments[1]}</button></li>
                    <li><button onClick={() => handleSetAssignment(inquiry, 2)} className={"dropdown-item " + (inquiry.AssignedTo === 2 ? "disabled" : "")} >{Assignments[2]}</button></li>
                    <li><button onClick={() => handleSetAssignment(inquiry, 3)} className={"dropdown-item " + (inquiry.AssignedTo === 3 ? "disabled" : "")} >{Assignments[3]}</button></li>
                </ul>
            </div>
            <div className="not-sawnee">
                <div id="floatingInputHelp" className="form-text">Provider</div>

                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input onChange={() => handleSetInquiryStatus(inquiry, "cfemc")} type="radio" className="btn-check" name="btnProvider" id="btnCFEMC" autoComplete="off" checked={inquiry.IsSawnee? false: true} />
                    <label className="btn btn-outline-primary btn-sm" htmlFor="btnCFEMC">CFEMC</label>

                    <input onChange={() => handleSetInquiryStatus(inquiry, "sawnee")} type="radio" className="btn-check" name="btnProvider" id="btnSawnee" autoComplete="off" checked={inquiry.IsSawnee? true: false} />
                    <label className="btn btn-outline-primary btn-sm" htmlFor="btnSawnee">Sawnee</label>
                </div>
            </div>

            <div>
                <div id="floatingPrintHelp" className="form-text">&nbsp;</div>

                <div className="btn-group" role="group" aria-label="Basic button group">
                    <button onClick={() => window.print()} className="btn-check" name="btnPrint" id="btnPrint">Print</button>
                    <label className="btn btn-outline-primary btn-sm" htmlFor="btnPrint"><span className="tf-icons bx bx-printer"></span> Print</label>
                </div>
            </div>
            <RemoveButton handleSetInquiryStatus={handleSetInquiryStatus} inquiry={inquiry} userList={userList} />
        </div>
    )
}

export default DetailsCommandBar;