/* eslint-env jquery */
import React, {useEffect, useState, useRef} from 'react';
import { axGetInquiries, getRecentInquiries} from '../../services/inquiry';
import { toast } from 'react-toastify';
import InquiryList from '../InquiryList/InquiryList';
import InquiryDetails from '../InquiryDetails/InquiryDetails';
import { InitialEmailTemplates } from '../Utils';
export default function Dashboard(props) {
    const [inquiryList, setInquiryList] = useState([]);
    const [templateList, setTemplateList] = useState({email: [], notes: []});
    const [inquiry, setInquiry] = useState([]);
    const [latestUpdateOn, setLatestUpdateOn] = useState();
    const [buddyList, setBuddyList]  = useState([]);

    const buddyListRef = useRef(buddyList);
    const inquiryListRef = useRef(inquiryList);
    const latestUpdatedOnRef = useRef(latestUpdateOn);

    const { socket, userList, currentUser } = props;

     
    useEffect(() => {
        buddyListRef.current = buddyList;
    }, [buddyList]);

    useEffect(() => {
        inquiryListRef.current = inquiryList;
    }, [inquiryList]);

    useEffect(() => {
        latestUpdatedOnRef.current = latestUpdateOn;
    }, [latestUpdateOn]);

    useEffect(() => {
        if(inquiry.length !== 0){
            setInquiry(inquiryList.find(d=> d.InquiryId === inquiry.InquiryId));
        }
        if(inquiryList.length > 0){
            const maxDate = inquiryList.reduce(function(prev, current) {
                return (prev.LastUpdatedOn > current.LastUpdatedOn) ? prev : current;
            })
            setLatestUpdateOn(maxDate.LastUpdatedOn);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inquiryList]);

    useEffect(() => {
        socket.on('inquiryPoll', (msg) => {
            //console.log({...msg, localLatest: latestUpdatedOnRef.current})
            if (msg.LastUpdatedOn > latestUpdatedOnRef.current){
                refreshList();
            }
        })
        socket.on('inquiryUpdate', (msg) => {
            if (msg.socket !== socket.id){
                refreshList();
            }
        });
        socket.on('openedDetails', (msg) => {
            if (msg.id !== socket.id){
                if (msg.inquiry === null){
                    setBuddyList(buddyListRef.current.filter(buddy => buddy.id !== msg.id));
                } else {
                    const oldBuddy = buddyListRef.current.find(buddy => buddy.id === msg.id);
                    if (oldBuddy){
                        setBuddyList(buddyListRef.current.map(buddy => {
                            if (buddy.id === msg.id) {return msg}
                            return buddy;
                        }))
                    } else {
                        
                        setBuddyList(current=> [...current, msg]);
                    }
                }
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const toastId = React.useRef(null)

    useEffect(() => {
        toastId.current = null;
        toastId.current = toast('Establishing Connection', { 
            isLoading: true,
            position: toast.POSITION.TOP_CENTER, 
            autoClose: false
        });
        axGetInquiries(2000, toastId).then(d => {
            setInquiryList(d);
        }).catch(err => {
            console.error(err);
            toast.update(toastId.current, {render: err.message, progress: 0, autoClose: false, theme: "colored", isLoading: false, type: "error"});
        });
    }, [])

    useEffect(() => {
            let db;
            const openRequest = indexedDB.open('pipeline', 2);
        
            openRequest.onsuccess = function (e) {
                db = e.target.result;
                const tx = db.transaction('templates', 'readonly');
                const store = tx.objectStore('templates');
                const request = store.getAll();
                request.onsuccess = function(e){
                    if(request.result === undefined){
                        console.log("Template Error");
                    } else {
                        console.log();
                        setTemplateList({
                            notes: [...request.result.filter(template=> template.notes === true)],
                            email: [...InitialEmailTemplates, ...request.result.filter(template=> template.notes === false)],
                        });
                    }
                }
                request.onerror = function(e){
                    console.error(e);
                }
            };
            openRequest.onerror = function (e) {
                console.log('onerror!');
                console.dir(e);
            };
    }, []);

    
    const increaseList = (numberOfInquiries) => {
        if (inquiryListRef.current.length !== 0) {
        toastId.current = null;
        toastId.current = toast('Establishing Connection', { 
            isLoading: true,
            position: toast.POSITION.TOP_CENTER, 
            autoClose: false
        });
        axGetInquiries(numberOfInquiries, toastId).then(newInquiryList => {
            const newList = inquiryListRef.current.map(item => {
                const updatedItem = newInquiryList.find(d=> d.InquiryId === item.InquiryId);
                if (updatedItem){
                    return updatedItem
                }
                return item;
            });
            const newUnique = newInquiryList.filter(item => !newList.includes(item));
            setInquiryList([...newList, ...newUnique]);
        });

        /*getInquiries(numberOfInquiries).then(newInquiryList => {
            const newList = inquiryListRef.current.map(item => {
                const updatedItem = newInquiryList.find(d=> d.InquiryId === item.InquiryId);
                if (updatedItem){
                    return updatedItem
                }
                return item;
            });
            const newUnique = newInquiryList.filter(item => !newList.includes(item));
            setInquiryList([...newList, ...newUnique]);
        });*/
    }
  }

  const refreshList = () => {
    if (inquiryListRef.current.length !== 0) {
        
        getRecentInquiries(latestUpdatedOnRef.current).then(recentUpdates => {
            const newList = inquiryListRef.current.map(item => {
                const updatedItem = recentUpdates.find(d=> d.InquiryId === item.InquiryId);
                if (updatedItem){
                    return updatedItem
                }
                return item;
            });
            const newUnique = recentUpdates.filter(item => !newList.includes(item));
            setInquiryList([...newList, ...newUnique]);
        });
    }
  }


  const handleInquiryListClick = value => {
    setInquiry(value);
    $('[data-bs-toggle="popover"]').popover("hide");
  }
  
  return(
    <>
        <InquiryList userList={userList} currentUser={currentUser} inquiry={inquiry} inquiryList={inquiryList} buddyList={buddyList} increaseList={increaseList} onClick={(p) => {handleInquiryListClick(p);}} />
        <InquiryDetails socket={socket} userList={userList} templateList={templateList} inquiry={inquiry} inquiryList={inquiryList} inquiryListChanger={setInquiryList} inquiryChanger={setInquiry} onClick={(p) => {handleInquiryListClick(p);}} />
    </>
  );
}