/* global $ */

import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendNotes, sendEmail } from "../../services/inquiry";

const NotesPanel = (props) => {
    const [emailTemplate, setEmailTemplate] = useState('');
    const [notesTemplate, setNotesTemplate] = useState('');

    const { templateList, inquiry, userList, handleNoteRefresh } = props;


    useEffect(()=> {
        var myCollapsibleEmail = document.getElementById('emailForm')
        myCollapsibleEmail.addEventListener('show.bs.collapse', function () {
            $('.details-table').addClass("writing-mode-email");
        });
        myCollapsibleEmail.addEventListener('hide.bs.collapse', function () {
            $('.details-table').removeClass("writing-mode-email");
        });
        var myCollapsibleNotes = document.getElementById('notesForm')
        myCollapsibleNotes.addEventListener('show.bs.collapse', function () {
            $('.details-table').addClass("writing-mode-notes");
        });
        myCollapsibleNotes.addEventListener('hide.bs.collapse', function () {
            $('.details-table').removeClass("writing-mode-notes");
        });
    }, []);

    return (
        <>
            <div className="timeline-card-buttons">
                <NotesButtonBar templateList={templateList} inquiry={inquiry} emailTemplate={emailTemplate} setNotesTemplate={setNotesTemplate} setEmailTemplate={setEmailTemplate} />
            </div>
            <div id="noteEmailForms">
                <div className="collapse" id='emailForm' data-bs-parent="#noteEmailForms">
                    <EmailForm templateList={templateList} inquiry={inquiry} emailTemplate={emailTemplate} setEmailTemplate={setEmailTemplate} handleNoteRefresh={handleNoteRefresh} />
                </div>
                <div className="collapse" id='notesForm' data-bs-parent="#noteEmailForms">
                    <NotesForm 
                        templateList={templateList} 
                        inquiry={inquiry}
                        userList={userList}
                        setNotesTemplate={setNotesTemplate}
                        notesTemplate={notesTemplate}
                        handleNoteRefresh={handleNoteRefresh} />
                </div>
            </div>
        </>
    )
}

export const NotesButtonBar = (props) => {
    const { inquiry, templateList, setEmailTemplate, setNotesTemplate } = props;
    const handleEmailTemplate = (e) => {
        setEmailTemplate(e);
        $('#emailForm').collapse("show");
    }
    const handleNotesTemplate = (e) => {
        setNotesTemplate(e);
        $('#notesForm').collapse("show");
    }


    return (
        <>
            <div className="btn-group">
                <button className="btn btn-outline-primary" data-bs-toggle="collapse" data-bs-target="#notesForm">New Note</button>
                <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu">
                    {templateList.notes.length === 0? <li><button className="dropdown-item disabled">No Templates</button></li>: ""}
                    
                    {templateList.notes.map(template => (
                            <li key={template.title}><button onClick={() => handleNotesTemplate(template)} className="dropdown-item">{template.title}</button></li>
                        ))}

                    <li>
                        <hr className="dropdown-divider" />
                    </li>
                    <li><a className="dropdown-item"  target="_blank" href="/preferences#notes-template">Manage Templates</a></li>
                </ul>
            </div>
            <span className="d-inline-block new-email-button-group not-sawnee" tabIndex={0} data-bs-toggle="tooltip" data-bs-html="true" title={inquiry.EmailAddress ? "" : "No email address"}>
                <div className="btn-group">
                    <button className="btn btn-outline-primary" data-bs-toggle="collapse" disabled={inquiry.EmailAddress ? false : true} data-bs-target="#emailForm">New Email</button>
                    <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split" disabled={inquiry.EmailAddress ? false : true} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="visually-hidden">Toggle Dropdown</span>
                    </button>
                    <ul className="dropdown-menu">
                        {templateList.email.map(template => (
                            <li key={template.title}><button onClick={() => handleEmailTemplate(template)} className="dropdown-item">{template.title}</button></li>
                        ))}
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <li><a className="dropdown-item" target="_blank" href="/preferences#email-template">Manage Templates</a></li>
                    </ul>
                </div>
            </span>

        </>
    )
}

export const EmailForm = (props) => {
    const [emailMessage, setEmailMessage] = useState('');
    const [bufferedEmailMessage, setBufferedEmailMessage] = useState('');
    const [emailSubject, setEmailSubject] = useState('True Natural Gas Service');

    const { inquiry, templateList, emailTemplate, setEmailTemplate, handleNoteRefresh } = props;


    useEffect(() => {
        if (inquiry.EmailAddress) {
            setEmailMessage('');
            setEmailSubject('True Natural Gas Service');
            setEmailTemplate({ title: '' });
            $('.new-email-button-group').tooltip("disable");
        } else {
            $('.new-email-button-group').tooltip("enable");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inquiry]);
    
    useEffect(() => {
        if(emailTemplate.title === "undo"){
            setEmailMessage(bufferedEmailMessage);
            setBufferedEmailMessage(emailMessage);
        } else if (emailTemplate.title === "clear"){
            setEmailMessage('');
            setBufferedEmailMessage(emailMessage);
            setEmailTemplate({title: ""});
        } else if (emailTemplate.title === ""){
            //nada
        } else {
            if (emailMessage && emailMessage.trim()) {
                setBufferedEmailMessage(emailMessage);
            }
            setEmailMessage(emailTemplate.body);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailTemplate]);

    const handleEmailMessageChange = (event) => {
        setEmailMessage(event.target.value);
    }
    const handleEmailSubjectChange = (event) => {
        setEmailSubject(event.target.value);
    }

    const handleEmailSend = () => {
        sendEmail(inquiry.InquiryId, inquiry.EmailAddress, emailSubject, emailMessage).then(d => {
            setEmailMessage("");
            $('#emailForm').collapse("hide");

            handleNoteRefresh();
        },
        (error) => {
            toast.error(error.message, {theme: "light"});
        });
    }

    const handleClose = (e) =>{
        $('#emailForm').collapse("hide");
    }
    const handleEmailTemplateSelect = (e) => {
        if (e.target.value === "") {
            setEmailTemplate({ title: "" });
        } else if (e.target.value === "undo") {
            setEmailTemplate({ title: "undo" });
        } else if (e.target.value === "clear") {
            setEmailTemplate({ title: "clear" });
        } else {
            const template = templateList.email.find(item => item.title === e.target.value);
            setEmailTemplate(template);
        }
    }

    return (
        <>
            <div className='row'>
                <div className="col mb-3">
                    <label htmlFor="emailModalTemplateButton" className="form-label">Load Email Template</label>
                    <select className="form-select" value={emailTemplate.title} onChange={handleEmailTemplateSelect} id="emailModalTemplateButton">
                        <option value=""></option>
                        {templateList.email.map(template => (
                            <option key={template.title} value={template.title}>{template.title}</option>
                        ))}
                        <option value="clear">Clear</option>
                        {bufferedEmailMessage &&
                            <option value="undo">Previous Message</option>
                        }


                    </select>
                </div>
            </div>

            <div className="row">
                <div className="col mb-3">
                    <label htmlFor="emailSubject" className="form-label">Subject</label>
                    <input type="text" onChange={handleEmailSubjectChange} id="emailSubject" className="form-control" placeholder="Email Subject Line" value={emailSubject} />
                </div>
            </div>
            <div className="row g-2">
                <div className="col mb-0">
                    <label htmlFor="emailEmailAddress" className="form-label">Email Address</label>
                    <input id="emailEmailAddress" type="text" readOnly className="form-control-plaintext" value={inquiry.EmailAddress} />
                </div>
            </div>
            <div className="row g-2">
                <div className="col mb-0">
                    <label htmlFor="emailBody" className="form-label">Message</label>
                    <textarea rows="14" type="text" onChange={handleEmailMessageChange} id="emailBody" className="form-control" placeholder="Message Here" value={emailMessage} />
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-label-secondary" onClick={handleClose}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={handleEmailSend} disabled={inquiry.EmailAddress ? false : true}>Send <i className="bx bx-send"></i></button>
            </div>
        </>

    )
}
export const NotesForm = (props) => {
    const { inquiry, userList, notesTemplate, setNotesTemplate, handleNoteRefresh } = props;
    const [noteMessage, setNoteMessage] = useState('');
    const [noteAuthor, setNoteAuthor] = useState('');

    const handleNoteMessageChange = (event) => {
        setNoteMessage(event.target.value);
    }
    const handleNoteAuthorChange = (event) => {
        setNoteAuthor(event.target.value);
    }
    useEffect(() => {
        if (userList.length > 0) {
            setNoteAuthor(userList.find(post => post.isCurrentUser).name);
        }
        setNoteMessage("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inquiry]);

    useEffect(() => {
        if (notesTemplate.body){
            setNoteMessage(notesTemplate.body);
            setNotesTemplate({body: ""})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notesTemplate]);

    const handleSendNote = () => {
        sendNotes(inquiry.InquiryId, noteAuthor, noteMessage).then(d => {
            setNoteMessage("");
            $('#notesForm').collapse("hide");
            handleNoteRefresh();
        },
        (error) => {
            toast.error(error.message, {theme: "light"});
        });
    }
    const handleClose = () =>{
        $('#notesForm').collapse("hide");
    }

    return (
        <>
            <div className="row">
                <div className="col mb-3">
                    <label htmlFor="noteAuthor" className="form-label">Author</label>
                    <input type="text" id="noteAuthor" onChange={handleNoteAuthorChange} className="form-control" placeholder="Your Name Here" value={noteAuthor} />
                </div>
            </div>
            <div className="row g-2">
                <div className="col mb-0">
                    <label htmlFor="noteBody" className="form-label">Message</label>
                    <textarea rows="10" onChange={handleNoteMessageChange} type="text" id="noteBody" className="form-control" placeholder="Message Here" value={noteMessage} />
                </div>
            </div>
            <div className='modal-footer'>
                <button type="button" className="btn btn-label-secondary" onClick={handleClose}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={handleSendNote}>Add Note <i className="bx bx-edit"></i></button>
            </div>

        </>
    )
}

export default NotesPanel;