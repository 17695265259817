import UserFront from "@userfront/react";
import jwt from 'jwt-decode';
import axios from "axios";
import { config } from './Constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function getUsers(){
    return new Promise(async (resolve, reject) =>{
        try {
            const response = await fetch(config.url.API_URL + '/users',{
                method: 'get',
                headers: new Headers({
                    Authorization: "Bearer " + UserFront.tokens.accessToken,
                }),
            });
            const responseJSON = await response.json();
            const loggedInUser = jwt(UserFront.tokens.accessToken);
            const userList  = responseJSON.map(user => {
                if (user.userId === loggedInUser.userId){
                    return ({...user, isCurrentUser: true});
                }
                return ({...user, isCurrentUser: false});
            }).sort((a, b) => a.name > b.name ? 1 : -1);
            resolve(userList);
        } catch (error) {
            reject(Error("rejected"));
        }
    })    
}

export function getNotes(inquiryId){
    return new Promise(async (resolve, reject) =>{
        try {
            const response = await fetch(config.url.API_URL + '/inquiry/' + inquiryId + "/notes",{
                method: 'get',
                headers: new Headers({
                    Authorization: "Bearer " + UserFront.tokens.accessToken,
                }),
            });
            const responseJSON = await response.json();
            resolve(responseJSON);
        } catch (error) {
            reject(Error("rejected"));
        }
    })    
}

export function sendEmail(inquiryId, recipient, subject, value){
    return new Promise(async (resolve, reject) =>{
        const templatePlaceholder = new RegExp('_[A-Z_]*_');
        if(templatePlaceholder.test(value)){
            reject(Error("Message not sent: _PLACEHOLDER_ text is still present in message body."));
            return;
        }
        if(!value || !value.trim().length){
            reject(Error("Message not sent: It's blank."));
            return;
        }
        try {
            const response = await fetch(config.url.API_URL + '/inquiry/' + inquiryId + "/email",{
                method: 'post',
                headers: {
                    Authorization: "Bearer " + UserFront.tokens.accessToken,
                    "Content-Type": "application/json"
                }, 
                body: JSON.stringify({value: value, subject: subject, recipient: recipient}),
            });
            const responseJSON = await response.json();
            if (responseJSON.error === "User Locked") { resolve ([])}
            resolve(responseJSON);
        } catch (error) {
            reject(Error(error));
        }
    })    
}

export function sendNotes(inquiryId, author, value){
    return new Promise(async (resolve, reject) =>{
        if(!value || !value.trim().length){ //It's blank or just spaces
            reject(Error("Note not saved: It's blank."));
            return;
        }
        try {
            const response = await fetch(config.url.API_URL + '/inquiry/' + inquiryId + "/notes",{
                method: 'post',
                headers: {
                    Authorization: "Bearer " + UserFront.tokens.accessToken,
                    "Content-Type": "application/json"
                }, 
                body: JSON.stringify({value: value, author: author}),
            });
            const responseJSON = await response.json();
            if (responseJSON.error === "User Locked") { resolve ([])}
            resolve(responseJSON);
        } catch (error) {
            reject(Error(error));
        }
    })    
}

export function getInquiry(inquiryId = null){
    const url = inquiryId? config.url.API_URL + "/inquiry/" + inquiryId : config.url.API_URL + '/inquiries';

    return new Promise(async (resolve) =>{
        const response = await fetch(url,{
            method: 'get',
            headers: new Headers({
                Authorization: "Bearer " + UserFront.tokens.accessToken,
            }),
        });
        const responseJSON = await response.json();
        if (responseJSON.error === "User Locked") { resolve ([])}
        resolve(responseJSON);
    })
}

export async function axGetInquiries(numberOfInquiries = 0, toastId = null){

    const url = config.url.API_URL;

    const client = axios.create({
        baseURL: url,
        headers: { Authorization: "Bearer " + UserFront.tokens.accessToken },
    })
    const {data} = await client.get( "/inquiries/" + numberOfInquiries, {
        onDownloadProgress(progressEvent){
            const progress = progressEvent.loaded / progressEvent.event.target.getResponseHeader('x-file-size')
            const percent = Math.round(progress * 100)
            if(toastId !== null){
                toast.update(toastId.current, { render: 'Downloading Records:' + percent + '%', progress, isLoading: false });
            }
        }})
    if(toastId !== null){
        toast.update(toastId.current, {render: "Finished!", progress: 0, autoClose: 1000, theme: "colored", isLoading: false, type: "success"});
    }
    return(data);
}
//Deprecated, use axGetInquiries
export function getInquiries(numberOfInquiries = 0){
    const url = config.url.API_URL + "/inquiries/" + numberOfInquiries;
    return new Promise(async (resolve) =>{
        const response = await fetch(url,{
            method: 'get',
            headers: new Headers({
                Authorization: "Bearer " + UserFront.tokens.accessToken,
            }),
        });
        const responseJSON = await response.json();
        if (responseJSON.error === "User Locked") { resolve ([])}
        resolve(responseJSON);
    })
}

export function getRecentInquiries(newerThan){
    const url = config.url.API_URL + "/inquiries/newerThan/" + newerThan;

    return new Promise(async (resolve) =>{
        const response = await fetch(url,{
            method: 'get',
            headers: new Headers({
                Authorization: "Bearer " + UserFront.tokens.accessToken,
            }),
        });
        const responseJSON = await response.json();
        resolve(responseJSON);
    })
}

export function setInquiryStatus(inquiryId, status){
    return new Promise(async (resolve, reject) =>{
        try {
            const response = await fetch(config.url.API_URL + '/inquiry/' + inquiryId + "/" + status,{
                method: 'PUT',
                headers: new Headers({
                    Authorization: "Bearer " + UserFront.tokens.accessToken,
                }),
            });
            const responseJSON = await response.json();
            resolve(responseJSON);
        } catch (error) {
            reject(Error("rejected"));
        }
    })    
}

export function setInquiryAssignment(inquiryId, assignmentId){
    return new Promise(async (resolve, reject) =>{
        try {
            const response = await fetch(config.url.API_URL + '/inquiry/' + inquiryId + "/assign/" + assignmentId,{
                method: 'PUT',
                headers: new Headers({
                    Authorization: "Bearer " + UserFront.tokens.accessToken,
                }),
            });
            const responseJSON = await response.json();
            resolve(responseJSON);
        } catch (error) {
            reject(Error("rejected"));
        }
    })    
}

export function setNeedsCreditCheck(inquiryId){
    return new Promise(async (resolve, reject) =>{
        try {
            const response = await fetch(config.url.API_URL + '/inquiry/' + inquiryId + "/markNeedsCreditCheck" ,{
                method: 'PUT',
                headers: new Headers({
                    Authorization: "Bearer " + UserFront.tokens.accessToken,
                }),
            });
            const responseJSON = await response.json();
            resolve(responseJSON);
        } catch (error) {
            reject(Error("rejected"));
        }
    })    
}