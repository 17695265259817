import React, {useState, useEffect} from 'react';
import './App.scss';
import {config} from '../../services/Constants'
import { getUsers } from '../../services/inquiry';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import {ToastContainer} from 'react-toastify'
import ProfileNav from '../ProfileNav/ProfileNav';
import Login, { Logout, Signup, Reset } from '../Login/Login';
import Preferences from '../Preferences/Preferences';
import UserFront from "@userfront/react";
import { isSawnee } from '../Utils';

import io from 'socket.io-client';
import Changelog from '../Changelog/Changelog';
import preval from 'preval.macro';

const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`;

UserFront.init(config.userfront.tenantId);

const LogoutButton = UserFront.build({
    toolId: "bblana"
});

const socket = io(config.url.API_URL, {
    withCredentials: true,
    extraHeaders: {
        authorization: "Bearer " + UserFront.tokens.accessToken
    }
});
socket.on('chat message', function (msg) {
    console.log(msg);
});

let db;

const openRequest = indexedDB.open('pipeline', 2);

openRequest.onupgradeneeded = function (e) {
  db = e.target.result;
  if (!db.objectStoreNames.contains('templates')) {
    db.createObjectStore('templates', { autoIncrement: true});
  }
};
openRequest.onerror = function (e) {
  console.log('onerror!');
  console.dir(e);
};

function App() {
    const [lastViwedChangelog, setLastViewedChangelog] = useState(localStorage.getItem("LastViewedChangelog") ? localStorage.getItem("LastViewedChangelog") : "");
    const [userList, setUserList] = useState([]);

    const handleSetLastViewedChangelog = (timestamp) =>{
        setLastViewedChangelog(timestamp);
    }
    useEffect(() => {
        getUsers().then(d => {
            setUserList(d);
        },
        (error) => {
            setUserList([]);
        });
    }, []);

    const currentUser = userList.find(user=>user.isCurrentUser)
    const memberType = isSawnee(currentUser) ? "sawnee" : "normal";
  return (
    <div id='app-wrapper' className={memberType + " wrapper"}>
        <ToastContainer />
        <nav className="navbar navbar-expand-lg">
            <div className='container-fluid logo-wrapper'>
                <a href='/' className='navbar-brand '>
                    <img src="/pipeline-logo-v1.webp" className='site-logo' alt="pipeline logo" />
                </a>
                <ProfileNav user={currentUser} buildDate={dateTimeStamp} lastViwedChangelog={lastViwedChangelog}/>
            </div>
        </nav>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
                   <RequireAuth userList={userList}>
                      <Dashboard userList={userList} currentUser={currentUser} socket={socket} />
                   </RequireAuth> 
                } />
                <Route path="/changelog" element={
                    <RequireAuth userList={userList}>
                        <Changelog handleSetLastViewedChangelog={handleSetLastViewedChangelog} buildDate={dateTimeStamp} />
                    </RequireAuth>
                } />
                <Route path="/preferences" element={<Preferences user={currentUser} />} />
                <Route path="/logout" element={<LogoutButton />} />
                <Route path="/dashboard" element={<Navigate to="/" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/reset" element={<Reset />} />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

const RequireAuth = ({children, ...props}) => {
    const { userList } = props;
    const currentUser = userList.find(user=>user.isCurrentUser)
    if (!UserFront.tokens.accessToken){
        return <Navigate to="/login" />;
    }
    if (!currentUser){ //If the user doesn't have access, they can't access the API anyway, so we'll just let them in the app and switch to an error message later. 
        return children;
    }
    
    if (currentUser && currentUser.locked){
        return (
        <div>
            <Logout />
        </div>
        );
    }
    const roles = Object.keys(currentUser.authorization).length === 0? [] : currentUser.authorization[config.userfront.tenantId].roles
    //If a user isn't in at least one of these, they can't really do anything
    if  (!roles.some(r => ["admin", "member", "contributor"].includes(r))){
        return <div>
            <p>You are not assigned the required role</p>
            <p>Asking an administrator to upgrade your role in Pipeline will fix insufficient permissions. You are user <span className='badge bg-label-dark'>{currentUser.userId}</span>.</p>
            <p>Once your role have been upgraded, it may take up to 5 minutes for this app to start working.</p>
            <Logout />
        </div>
    }
    return children;
}
export default App;